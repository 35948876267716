var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    {
      staticStyle: {
        background: "#fff",
        height: "auto",
        overflow: "overlay",
        "flex-direction": "column",
      },
    },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: {
            padding: "20px",
            "flex-grow": "1",
            overflow: "hidden overlay",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticClass: "inside-menu-wrap",
                  class: _vm.navBarFixed == true ? "navBarWrap" : "",
                  staticStyle: { height: "40px" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "inside-menu" },
                    _vm._l(_vm.menulist, function (item, index) {
                      return _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                ((item.href == "#a04" ||
                                  item.href == "#a05" ||
                                  item.href == "#a06") &&
                                  _vm.drug.oligomict === "1" &&
                                  _vm.resultNameOptions.length > 0) ||
                                (item.href != "#a04" &&
                                  item.href != "#a05" &&
                                  item.href != "#a06"),
                              expression:
                                "((item.href=='#a04' || item.href=='#a05' || item.href=='#a06') && drug.oligomict==='1' && resultNameOptions.length>0) ||(item.href!='#a04' && item.href!='#a05'&& item.href!='#a06')",
                            },
                          ],
                          key: index,
                          class: _vm.flag == index ? "liActive" : "",
                          on: {
                            click: function ($event) {
                              return _vm.chooseMenu(index)
                            },
                          },
                        },
                        [
                          _c("a", { attrs: { href: item.href } }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-col", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.navBarFixed == true,
                    expression: "navBarFixed == true",
                  },
                ],
                staticStyle: { height: "40px" },
                attrs: { span: 24 },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "title-bar", attrs: { span: 24, id: "a01" } },
            [_vm._v("药物基本信息")]
          ),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "status-icon": "",
                model: _vm.drug,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.drug.id,
                      expression: "drug.id",
                    },
                  ],
                  attrs: { label: "药物ID:" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px", float: "left" },
                    attrs: { disabled: true },
                    model: {
                      value: _vm.drug.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.drug, "id", $$v)
                      },
                      expression: "drug.id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "中西药物标识",
                    prop: "classify",
                    rules: [
                      {
                        required: true,
                        message: "请输入中西药物标识",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px", float: "left" },
                      attrs: { placeholder: "请选择中西药物标识" },
                      model: {
                        value: _vm.drug.classify,
                        callback: function ($$v) {
                          _vm.$set(_vm.drug, "classify", $$v)
                        },
                        expression: "drug.classify",
                      },
                    },
                    _vm._l(_vm.cwMedicineIdentification, function (item) {
                      return _c("el-option", {
                        key: item.conceptId,
                        attrs: {
                          label: item.conceptVal,
                          value: item.conceptId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否单成分药物:", prop: "oligomict" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      on: { change: _vm.oligomictChange },
                      model: {
                        value: _vm.drug.oligomict,
                        callback: function ($$v) {
                          _vm.$set(_vm.drug, "oligomict", $$v)
                        },
                        expression: "drug.oligomict",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v("否"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("是"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.drug.dkbDrugAliasList, function (item, index) {
                return _c(
                  "el-form-item",
                  {
                    key: "drug-name-" + index,
                    attrs: {
                      label: index == 0 ? "药物名称:" : "",
                      rules: [
                        {
                          required: true,
                          message: "请输入药物名称",
                          trigger: "blur",
                        },
                        { validator: _vm.validateDrugName, trigger: "blur" },
                      ],
                      prop: "dkbDrugAliasList." + index + ".alias",
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "450px", float: "left" },
                      attrs: {
                        placeholder: "请输入药物名称",
                        maxlength: "300",
                        clearable: "",
                        "show-word-limit": "",
                      },
                      model: {
                        value: item.alias,
                        callback: function ($$v) {
                          _vm.$set(item, "alias", $$v)
                        },
                        expression: "item.alias",
                      },
                    }),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "80px", float: "left" },
                        attrs: { placeholder: "请选择语言" },
                        model: {
                          value: item.aliasLangue,
                          callback: function ($$v) {
                            _vm.$set(item, "aliasLangue", $$v)
                          },
                          expression: "item.aliasLangue",
                        },
                      },
                      _vm._l(_vm.languageConcep, function (item) {
                        return _c("el-option", {
                          key: item.conceptId,
                          attrs: {
                            label: item.conceptVal,
                            value: item.conceptId,
                          },
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { float: "left", "margin-bottom": "0px" },
                        attrs: {
                          rules: [
                            {
                              required: true,
                              message: "请选择名称类型",
                              trigger: "blur",
                            },
                            {
                              validator: _vm.validateAliasType,
                              trigger: "blur",
                            },
                          ],
                          prop: "dkbDrugAliasList." + index + ".aliasType",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "120px", float: "left" },
                            attrs: { placeholder: "请选择类型" },
                            model: {
                              value: item.aliasType,
                              callback: function ($$v) {
                                _vm.$set(item, "aliasType", $$v)
                              },
                              expression: "item.aliasType",
                            },
                          },
                          _vm._l(_vm.aliasTypeConcept, function (item) {
                            return _c("el-option", {
                              key: item.conceptId,
                              attrs: {
                                label: item.conceptVal,
                                value: item.conceptId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c("i", {
                      staticClass: "el-icon-remove",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.delSubstanceName(item)
                        },
                      },
                    }),
                    _c(
                      "el-radio",
                      {
                        staticStyle: {
                          "margin-right": "10px",
                          padding: "8px 8px 0 5px",
                        },
                        attrs: { label: index, border: "", size: "small" },
                        on: {
                          change: function ($event) {
                            return _vm.primaryChange($event, "name", index)
                          },
                        },
                        model: {
                          value: _vm.drug.namePrimary,
                          callback: function ($$v) {
                            _vm.$set(_vm.drug, "namePrimary", $$v)
                          },
                          expression: "drug.namePrimary",
                        },
                      },
                      [_vm._v(" 首选项 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.showResourceTable(
                              "dkb_drug_alias",
                              "dkbDrugAliasList",
                              index,
                              "name"
                            )
                          },
                        },
                      },
                      [_vm._v("添加来源")]
                    ),
                    index == _vm.drug.dkbDrugAliasList.length - 1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "addData",
                            staticStyle: {
                              width: "80px",
                              padding: "0px 0px 0px 16px",
                            },
                            attrs: { type: "primary", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.addSubstanceName("中文")
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-circle-plus",
                              staticStyle: {
                                color: "#7C9DF7",
                                width: "16px",
                                height: "16px",
                                display: "inline-block",
                                float: "left",
                              },
                            }),
                            _c("svg-icon", {
                              staticStyle: { width: "24px", height: "16px" },
                              attrs: { "icon-class": "china" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    index == _vm.drug.dkbDrugAliasList.length - 1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "addData",
                            staticStyle: {
                              width: "80px",
                              padding: "0px 0px 0px 16px",
                            },
                            attrs: { type: "primary", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.addSubstanceName("英语")
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-circle-plus",
                              staticStyle: {
                                color: "#7C9DF7",
                                width: "16px",
                                height: "16px",
                                display: "inline-block",
                                float: "left",
                              },
                            }),
                            _c("svg-icon", {
                              staticStyle: { width: "24px", height: "16px" },
                              attrs: { "icon-class": "yingguoguoqi" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm._l(_vm.drug.dkbDrugDescList, function (item, index) {
                return _c(
                  "el-form-item",
                  {
                    key: "drug-desc-" + index,
                    attrs: {
                      label: index == 0 ? "药物描述:" : "",
                      rules: [
                        {
                          required: true,
                          message: "请输入药物描述",
                          trigger: "blur",
                        },
                        { validator: _vm.validateDrugDesc, trigger: "blur" },
                      ],
                      prop: "dkbDrugDescList." + index + ".drugDesc",
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "subDescribe",
                      staticStyle: { width: "500px", float: "left" },
                      attrs: {
                        type: "textarea",
                        rows: 6,
                        placeholder: "请输入药物描述",
                        maxlength: "200",
                        clearable: "",
                        "show-word-limit": "",
                      },
                      model: {
                        value: item.drugDesc,
                        callback: function ($$v) {
                          _vm.$set(item, "drugDesc", $$v)
                        },
                        expression: "item.drugDesc",
                      },
                    }),
                    _c("i", {
                      staticClass: "el-icon-remove",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.delDrugDesc(item)
                        },
                      },
                    }),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: index, border: "" },
                        on: {
                          change: function ($event) {
                            return _vm.primaryChange($event, "desc", index)
                          },
                        },
                        model: {
                          value: _vm.drug.descPrimary,
                          callback: function ($$v) {
                            _vm.$set(_vm.drug, "descPrimary", $$v)
                          },
                          expression: "drug.descPrimary",
                        },
                      },
                      [_vm._v(" 设置为推荐值 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.showResourceTable(
                              "dkb_drug_desc",
                              "dkbDrugDescList",
                              index,
                              "drugDesc"
                            )
                          },
                        },
                      },
                      [_vm._v("添加来源 ")]
                    ),
                  ],
                  1
                )
              }),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "addData",
                      staticStyle: { width: "500px" },
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.addDrugDesc },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-circle-plus el-icon--left",
                        staticStyle: {
                          color: "#7C9DF7",
                          width: "16px",
                          height: "16px",
                          display: "inline-block",
                        },
                      }),
                      _vm._v(" 添加药物描述 "),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "title-bar", attrs: { span: 24, id: "a02" } },
                [_vm._v("药物成分")]
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "drugComponentForm",
                          attrs: {
                            "status-icon": "",
                            model: _vm.drugComponent,
                            "label-width": "120px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "药物成分",
                                prop: "drugComponentQueryStr",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入查询内容",
                                    trigger: "blur",
                                  },
                                  {
                                    min: 1,
                                    max: 30,
                                    message: "查询内容长度在 1 到 30 个字符",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    remote: "",
                                    "popper-class": "select_component",
                                    "remote-method": _vm.remoteMethod,
                                    "reserve-keyword": "",
                                    placeholder: "输入物质名称",
                                  },
                                  model: {
                                    value:
                                      _vm.drugComponent.drugComponentQueryStr,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.drugComponent,
                                        "drugComponentQueryStr",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "drugComponent.drugComponentQueryStr",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "clearfix table_Header" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "100px",
                                            flex: "1",
                                          },
                                        },
                                        [_vm._v("物质ID")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "280px",
                                            flex: "2",
                                          },
                                        },
                                        [_vm._v("物质名称")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "280px",
                                            flex: "2",
                                          },
                                        },
                                        [_vm._v("添加")]
                                      ),
                                    ]
                                  ),
                                  _vm._l(
                                    _vm.resultNameOptions02,
                                    function (item, index) {
                                      return _c(
                                        "el-option",
                                        {
                                          key:
                                            "substance" +
                                            item.substanceId +
                                            item.name,
                                          attrs: {
                                            label: item.name,
                                            value: item.substanceId,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "clearfix spanWrap",
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    width: "100px",
                                                    flex: "1",
                                                  },
                                                  attrs: {
                                                    title: item.substanceId,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.substanceId)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    width: "280px",
                                                    flex: "2",
                                                  },
                                                  attrs: { title: item.name },
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    width: "280px",
                                                    flex: "2",
                                                    color: "#2B60F8",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.choosedrugComponentData(
                                                        index,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("添加")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "addClass",
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: {
                                    icon: "el-icon-circle-plus-outline",
                                  },
                                  on: { click: _vm.addSubstance },
                                },
                                [_vm._v(" 新增物质 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.drug.oligomict === "1",
                                      expression: "drug.oligomict==='1'",
                                    },
                                  ],
                                  staticClass: "addClass",
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: {
                                    icon: "el-icon-circle-plus-outline",
                                    title:
                                      "进入物质新增菜单并自动填充当前药物的名称和描述信息",
                                  },
                                  on: { click: _vm.quickAddSubstance },
                                },
                                [_vm._v(" 快捷新增物质 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { padding: "0px 0px 15px 15px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "rno",
                          attrs: {
                            data: _vm.resultNameOptions,
                            height: _vm.drugIngredientTableHeight,
                            border: "",
                            stripe: "",
                            "header-cell-style": { "text-align": "center" },
                            "cell-style": { "text-align": "center" },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              property: "substanceId",
                              label: "物质ID",
                              width: "150",
                              sortable: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              property: "substanceName",
                              label: "物质名称",
                              sortable: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              property: "",
                              label: "成分类型",
                              width: "250",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: scope.row.substanceType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "substanceType",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.substanceType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.drugComponentTypeOptions,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.conceptId,
                                            attrs: {
                                              label: item.conceptVal,
                                              value: item.conceptId,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作", width: "150" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "#FF3333" },
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteRow(
                                              scope.$index,
                                              _vm.resultNameOptions
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("template", { slot: "empty" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/no-booking.svg"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "title-bar", attrs: { span: 24, id: "a03" } },
                [_vm._v("药物组")]
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "drugGroupQueryForm",
                          attrs: {
                            "status-icon": "",
                            model: _vm.addDrugGroupForm,
                            "label-width": "120px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "所属药物组:" } },
                            [
                              _vm._l(
                                _vm.drug.dkbDrugGroupMappingList,
                                function (item, index) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key:
                                        item.drugGroupId + item.drugGroupName,
                                      staticStyle: {
                                        height: "32px",
                                        padding: "0 10px",
                                        "line-height": "30px",
                                        "font-size": "14px",
                                      },
                                      attrs: {
                                        closable: "",
                                        "disable-transitions": false,
                                      },
                                      on: {
                                        close: function ($event) {
                                          return _vm.handleRemoveDrugGroup(
                                            item,
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.drugGroupName) + " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _vm.addDrugGroupInputVisible
                                ? _c(
                                    "el-select",
                                    {
                                      ref: "saveTagInput",
                                      staticClass: "input-new-tag",
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        remote: "",
                                        "reserve-keyword": "",
                                        placeholder: "请输入关键字搜索",
                                        "remote-method":
                                          _vm.selectDrugGroupList,
                                        loading: _vm.loading,
                                      },
                                      on: {
                                        change: _vm.handleSelectDrugGroup,
                                        blur: _vm.addDrugGroupInputBlur,
                                        "visible-change":
                                          _vm.addDrugGroupInputBlur,
                                      },
                                      model: {
                                        value: _vm.addDrugGroupForm.id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addDrugGroupForm,
                                            "id",
                                            $$v
                                          )
                                        },
                                        expression: "addDrugGroupForm.id",
                                      },
                                    },
                                    _vm._l(
                                      _vm.drugComponentTableData,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.id + "drugGroup",
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      staticClass: "button-new-tag addClass",
                                      attrs: {
                                        icon: "el-icon-link",
                                        size: "small",
                                      },
                                      on: { click: _vm.showDrugGroupInput },
                                    },
                                    [_vm._v("关联药物组")]
                                  ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "addClass",
                                  attrs: {
                                    icon: "el-icon-circle-plus-outline",
                                    size: "small",
                                  },
                                  on: { click: _vm.addDrugGroup },
                                },
                                [_vm._v(" 新增药物组 ")]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.drug.oligomict === "1" && _vm.resultNameOptions.length > 0
                ? _c(
                    "div",
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "title-bar",
                          attrs: { span: 24, id: "a04" },
                        },
                        [_vm._v("UNII物质信息")]
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { "padding-bottom": "10px" },
                              attrs: { span: 12, offset: 2 },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { background: "#2B60F8" },
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.queryUnii },
                                },
                                [_vm._v("UNII查询 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "warning",
                                    icon: "el-icon-delete",
                                    plain: "",
                                  },
                                  on: { click: _vm.clearUnii },
                                },
                                [_vm._v("清除 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-col", {
                            staticStyle: { height: "180px" },
                            attrs: { span: 2 },
                          }),
                          _c(
                            "el-col",
                            {
                              staticStyle: { height: "180px" },
                              attrs: { span: 3 },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "150px",
                                    height: "150px",
                                    background: "#EBF0FA",
                                    border: "1px solid #BDD3FF",
                                  },
                                },
                                [
                                  !_vm.drug.dkbSubstanceStructure
                                    .structuralPicture
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/no-image.jpg"),
                                          alt: "image",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.drug.dkbSubstanceStructure
                                    .structuralPicture
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                        },
                                        attrs: {
                                          src:
                                            "data:image/png;base64," +
                                            _vm.drug.dkbSubstanceStructure
                                              .structuralPicture,
                                          alt: "image",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { height: "auto" },
                              attrs: { span: 19 },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "UNII:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.substanceCode.unii) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "CAS:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.substanceCode.cas) +
                                              " "
                                          ),
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.substanceCode.unii &&
                                                  !_vm.substanceCode.cas,
                                                expression:
                                                  "substanceCode.unii && !substanceCode.cas",
                                              },
                                            ],
                                            staticClass:
                                              "el-icon-edit cas-edit",
                                            on: {
                                              click: function ($event) {
                                                return _vm.editCas(
                                                  null,
                                                  _vm.substanceCode.unii
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "立体化学:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.drug.dkbSubstanceStructure
                                                  .stereochemistry
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "分子式:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.drug.dkbSubstanceStructure
                                                  .molecularFormula
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "旋光性:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.drug.dkbSubstanceStructure
                                                  .opticalActivity
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "分子量:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.drug.dkbSubstanceStructure
                                                  .molecularWeight
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { "margin-left": "120px" },
                              attrs: { span: 20 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "SMILES:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.drug.dkbSubstanceStructure.smiles
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "margin-left": "120px" },
                              attrs: { span: 20 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "INCHI_KEY:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.drug.dkbSubstanceStructure.inchiKey
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "margin-left": "120px" },
                              attrs: { span: 20 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "INCHI:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.drug.dkbSubstanceStructure.inchi
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.drug.oligomict === "1" &&
                        _vm.resultNameOptions.length > 0,
                      expression:
                        "drug.oligomict==='1' && resultNameOptions.length>0",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "title-bar",
                      attrs: { span: 24, id: "a05" },
                    },
                    [_vm._v("物质属性")]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "",
                        prop: "tableName",
                        id: "recommendProps",
                      },
                    },
                    _vm._l(_vm.substanceProps, function (item) {
                      return _c(
                        "el-button",
                        {
                          key: "substancePropsButton-" + item.conceptId,
                          attrs: { plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.addSubstanceProp(
                                $event,
                                item.conceptId
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.conceptVal) + " ")]
                      )
                    }),
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "margin-left": "80px",
                        "padding-bottom": "10px",
                        "font-size": "14px",
                      },
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "200px",
                            float: "left",
                            "text-align": "center",
                          },
                        },
                        [_vm._v("物质属性名称")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "200px",
                            float: "left",
                            "text-align": "center",
                          },
                        },
                        [_vm._v("物质属性值")]
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _vm._l(
                        _vm.drug.dkbSubstanceProps,
                        function (item, index) {
                          return _c(
                            "el-form-item",
                            {
                              key: "substance-prop-" + index,
                              attrs: {
                                prop: "dkbSubstanceProps." + index + ".name",
                                rules: [
                                  {
                                    validator: _vm.validateSubstanceProps,
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    float: "left",
                                  },
                                  attrs: { placeholder: "请选择物质属性名称" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.propsNameSelectChange(
                                        $event,
                                        index
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.name,
                                    callback: function ($$v) {
                                      _vm.$set(item, "name", $$v)
                                    },
                                    expression: "item.name",
                                  },
                                },
                                _vm._l(_vm.substanceProps, function (item) {
                                  return _c("el-option", {
                                    key: item.conceptId,
                                    attrs: {
                                      label: item.conceptVal,
                                      value: item.conceptId,
                                    },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    float: "left",
                                  },
                                  attrs: {
                                    placeholder: "请选择物质属性值",
                                    disabled: item.name === null ? true : false,
                                    filterable: "",
                                    "filter-method": function (val) {
                                      _vm.substancePropsValFilter(
                                        val,
                                        _vm.drug.dkbSubstanceProps[index].name
                                      )
                                    },
                                  },
                                  on: {
                                    focus: function ($event) {
                                      _vm.substancePropsValOptions[
                                        _vm.drug.dkbSubstanceProps[index].name
                                      ] =
                                        _vm.substancePropsVal[
                                          _vm.drug.dkbSubstanceProps[index].name
                                        ]
                                    },
                                  },
                                  model: {
                                    value: item.value,
                                    callback: function ($$v) {
                                      _vm.$set(item, "value", $$v)
                                    },
                                    expression: "item.value",
                                  },
                                },
                                _vm._l(
                                  _vm.substancePropsValOptions[
                                    _vm.drug.dkbSubstanceProps[index].name
                                  ],
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.conceptId,
                                      attrs: {
                                        label: item.conceptVal,
                                        value: item.conceptId,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                              _c("i", {
                                staticClass: "el-icon-remove",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.delSubstanceprop(item)
                                  },
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showResourceTable(
                                        "dkb_substance_props",
                                        "dkbSubstanceProps",
                                        index,
                                        "name"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 添加来源 ")]
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "addData",
                              staticStyle: { width: "400px" },
                              attrs: { type: "primary", plain: "" },
                              on: { click: _vm.addSubstanceProp },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-circle-plus el-icon--left",
                                staticStyle: {
                                  color: "#7C9DF7",
                                  width: "16px",
                                  height: "16px",
                                  display: "inline-block",
                                },
                              }),
                              _vm._v(" 添加 "),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.drug.oligomict === "1" &&
                        _vm.resultNameOptions.length > 0,
                      expression:
                        "drug.oligomict==='1' && resultNameOptions.length>0",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "title-bar",
                      attrs: { span: 24, id: "a06" },
                    },
                    [_vm._v("关联物质")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "关联物质:", prop: "relevanceId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "500px" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                "reserve-keyword": "",
                                placeholder: "请输入关键字搜索",
                                "remote-method": _vm.queryRelevanceSubstance,
                                loading: _vm.loading,
                              },
                              model: {
                                value: _vm.drug.relevanceId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.drug, "relevanceId", $$v)
                                },
                                expression: "drug.relevanceId",
                              },
                            },
                            _vm._l(_vm.relevanceSubstanceList, function (item) {
                              return _c("el-option", {
                                key:
                                  item.substanceId + "relevanceSubstanceList",
                                attrs: {
                                  label: item.name,
                                  value: item.substanceId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-col",
            {
              staticClass: "bottom-bar",
              staticStyle: {
                height: "80px",
                "padding-right": "0px",
                position: "fixed",
                right: "30px",
                bottom: "10px",
              },
              attrs: { span: 20 },
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.drug.id,
                      expression: "drug.id",
                    },
                  ],
                  staticStyle: { float: "right", "margin-left": "10px" },
                  on: { click: _vm.editNext },
                },
                [_vm._v("下一个")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.drug.id,
                      expression: "drug.id",
                    },
                  ],
                  staticStyle: { float: "right", "margin-left": "10px" },
                  on: { click: _vm.editPrev },
                },
                [_vm._v("上一个")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-left": "10px" },
                  on: { click: _vm.returnPage },
                },
                [_vm._v("返回")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", display: "none" },
                  on: { click: _vm.resetForm },
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(1)
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(2)
                    },
                  },
                },
                [_vm._v("保存并关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "引用来源管理",
            visible: _vm.resourceTableVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.resourceTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: {
                        type: "primary",
                        icon: "el-icon-circle-plus-outline",
                        size: "small",
                      },
                      on: { click: _vm.addResource },
                    },
                    [_vm._v("添加 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "resourceTableForm",
              attrs: { "status-icon": "", model: _vm.resourceTableData },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    stripe: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    data: _vm.resourceTableData.list,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { property: "date", label: "引用资源类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "list." +
                                    scope.$index +
                                    "." +
                                    "resourceTypeId",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择药物名称",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      placeholder: "引用资源类型",
                                      size: "small",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.resourceTypeChange(
                                          $event,
                                          scope.$index
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.resourceTypeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "resourceTypeId",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.resourceTypeId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.resourceTypeOption,
                                    function (dict) {
                                      return _c("el-option", {
                                        key: "resourceTypeOption" + dict.id,
                                        attrs: {
                                          label: dict.typeName,
                                          value: dict.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { property: "name", label: "引用资源名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "300px" },
                                attrs: {
                                  filterable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  placeholder: "请输入关键词",
                                  "remote-method": function (query) {
                                    _vm.resourceNameQuery(
                                      query,
                                      scope.row.resourceTypeId
                                    )
                                  },
                                  loading: _vm.loading,
                                  disabled: !scope.row.resourceTypeId
                                    ? true
                                    : false,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.resourceNameChange(
                                      $event,
                                      scope.$index
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.resourceId,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "resourceId", $$v)
                                  },
                                  expression: "scope.row.resourceId",
                                },
                              },
                              [
                                _vm._l(_vm.resourceDataOption, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                _vm._l([scope.row], function (item) {
                                  return _c("el-option", {
                                    key: item.resourceId + "original",
                                    attrs: {
                                      label: item.resourceName,
                                      value: item.resourceId,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      "class-name": "small-padding fixed-width",
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#FF3333" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleResourceDelete(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-top": "10px" }, attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: {
                        type: "primary",
                        icon: "el-icon-circle-check",
                        size: "small",
                      },
                      on: { click: _vm.saveResource },
                    },
                    [_vm._v("确定 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: { icon: "el-icon-circle-close", size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.resourceTableVisible = false
                        },
                      },
                    },
                    [_vm._v("取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "UNII物质检索",
            width: "60%",
            visible: _vm.unii.uniiTableVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.unii, "uniiTableVisible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "uniiQueryForm",
              attrs: { "status-icon": "", model: _vm.unii.form },
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px", float: "left" },
                      attrs: {
                        title:
                          "搜索匹配方式仅适用于别名,unii和cas编码都是精确匹配",
                      },
                      model: {
                        value: _vm.unii.form.queryType,
                        callback: function ($$v) {
                          _vm.$set(_vm.unii.form, "queryType", $$v)
                        },
                        expression: "unii.form.queryType",
                      },
                    },
                    _vm._l(_vm.queryTypeOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _c("el-input", {
                    staticStyle: { width: "400px", float: "left" },
                    attrs: {
                      placeholder: "请输入unii编码/cas编码/别名",
                      maxlength: "250",
                      clearable: "",
                      "show-word-limit": "",
                    },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.queryUnii($event)
                      },
                    },
                    model: {
                      value: _vm.unii.form.searchValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.unii.form, "searchValue", $$v)
                      },
                      expression: "unii.form.searchValue",
                    },
                  }),
                  _c("el-input", { staticStyle: { display: "none" } }),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "margin-left": "20px",
                        background: "#2B60F8",
                      },
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.queryUnii },
                    },
                    [_vm._v("检索 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.unii.uniiTableData,
                height: 300,
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { property: "unii", label: "unii编码", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { property: "cas", label: "CAS", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { property: "displayName", label: "名称" },
              }),
              _c("el-table-column", {
                attrs: { property: "displayName", label: "别名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              trigger: "click",
                              placement: "top",
                              width: "400",
                            },
                          },
                          [
                            _vm._l(scope.row.aliasList, function (item, index) {
                              return _c(
                                "p",
                                { key: "unii" + scope.row.id + "_" + index },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            }),
                            _c(
                              "span",
                              {
                                staticClass: "name-wrapper",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#2B60F8",
                                      "font-weight": "700",
                                    },
                                    attrs: { title: "点击查看全部别名" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.aliasList
                                            ? scope.row.aliasList[0].name
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.chooseUniiData(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("选择 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.isUnii === false
                ? _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ])
                : _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-left-data.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                  ]),
            ],
            2
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.unii.total > 0,
                expression: "unii.total>0",
              },
            ],
            attrs: {
              total: _vm.unii.total,
              page: _vm.unii.form.pageNum,
              limit: _vm.unii.form.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.unii.form, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.unii.form, "pageSize", $event)
              },
              pagination: _vm.queryUnii,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "差异比较",
            visible: _vm.compareResultDialogVisible,
            "close-on-click-modal": false,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.compareResultDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "title-bar", attrs: { span: 24 } },
                    [_vm._v("药物信息")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "title-bar", attrs: { span: 24 } },
                    [_vm._v("物质信息")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { height: "400px", overflow: "auto" } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-card",
                            {
                              staticClass: "box-card",
                              staticStyle: {
                                width: "400px",
                                "margin-bottom": "10px",
                              },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "header" }, slot: "header" },
                                [_c("span", [_vm._v("药物名称")])]
                              ),
                              _vm._l(
                                _vm.drug.dkbDrugAliasList,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: "drug-name-" + index,
                                      staticStyle: {
                                        "font-size": "14px",
                                        "margin-bottom": "18px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-tag",
                                        { attrs: { size: "mini" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.languageConceptMap.get(
                                                item.aliasLangue
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-tag",
                                        {
                                          staticStyle: { "margin-left": "6px" },
                                          attrs: { size: "mini" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.aliasTypeConceptMap.get(
                                                item.aliasType
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("span", [
                                        _vm._v(" " + _vm._s(item.alias)),
                                      ]),
                                      _c(
                                        "el-tag",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.isPrimary == "1",
                                              expression: "item.isPrimary=='1'",
                                            },
                                          ],
                                          attrs: { type: "info", size: "mini" },
                                        },
                                        [_vm._v("默认值")]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-card",
                            {
                              staticClass: "box-card",
                              staticStyle: { width: "400px" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "header" }, slot: "header" },
                                [_c("span", [_vm._v("药物描述")])]
                              ),
                              _vm._l(
                                _vm.drug.dkbDrugDescList,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: "drug-desc-" + index,
                                      staticStyle: {
                                        "font-size": "14px",
                                        "margin-bottom": "18px",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(" " + _vm._s(item.drugDesc)),
                                      ]),
                                      _c(
                                        "el-tag",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.isPrimary == "1",
                                              expression: "item.isPrimary=='1'",
                                            },
                                          ],
                                          attrs: { type: "info", size: "mini" },
                                        },
                                        [_vm._v("默认值")]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-card",
                            {
                              staticClass: "box-card",
                              staticStyle: {
                                width: "400px",
                                "margin-bottom": "10px",
                              },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "header" }, slot: "header" },
                                [_c("span", [_vm._v("物质名称")])]
                              ),
                              _vm._l(
                                _vm.drug.dkbSubstanceName,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: "substance-name-" + index,
                                      staticStyle: {
                                        "font-size": "14px",
                                        "margin-bottom": "18px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-tag",
                                        { attrs: { size: "mini" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.languageConceptMap.get(
                                                item.nameLangue
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-tag",
                                        {
                                          staticStyle: { "margin-left": "6px" },
                                          attrs: { size: "mini" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.aliasTypeConceptMap.get(
                                                item.aliasType
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("span", [
                                        _vm._v(" " + _vm._s(item.name)),
                                      ]),
                                      _c(
                                        "el-tag",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.isPrimary == "1",
                                              expression: "item.isPrimary=='1'",
                                            },
                                          ],
                                          attrs: { type: "info", size: "mini" },
                                        },
                                        [_vm._v("默认值")]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-card",
                            {
                              staticClass: "box-card",
                              staticStyle: { width: "400px" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "header" }, slot: "header" },
                                [_c("span", [_vm._v("物质描述")])]
                              ),
                              _vm._l(
                                _vm.drug.dkbSubstanceDesc,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: "substance-desc-" + index,
                                      staticStyle: {
                                        "font-size": "14px",
                                        "margin-bottom": "18px",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " + _vm._s(item.substanceDesc)
                                        ),
                                      ]),
                                      _c(
                                        "el-tag",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.isPrimary == "1",
                                              expression: "item.isPrimary=='1'",
                                            },
                                          ],
                                          attrs: { type: "info", size: "mini" },
                                        },
                                        [_vm._v("默认值")]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-top": "10px" }, attrs: { span: 24 } },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "top-start",
                        title: "",
                        width: "200",
                        trigger: "hover",
                        content:
                          "使用当前药物的名称和描述信息，覆盖成分物质的名称和描述信息",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                          attrs: {
                            slot: "reference",
                            type: "primary",
                            size: "small",
                          },
                          on: { click: _vm.overrideUpdate },
                          slot: "reference",
                        },
                        [_vm._v("覆盖更新 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "top-start",
                        title: "",
                        width: "200",
                        trigger: "hover",
                        content:
                          "将当前药物和成分物质的名称和描述信息合并后更新",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                          attrs: {
                            slot: "reference",
                            type: "primary",
                            size: "small",
                          },
                          on: { click: _vm.mergeUpdate },
                          slot: "reference",
                        },
                        [_vm._v("合并更新 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "top-start",
                        title: "",
                        width: "200",
                        trigger: "hover",
                        content:
                          "忽略差异，药物的名称和描述信息直接保存，成分物质的名称和描述信息不做任何处理",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                          attrs: {
                            slot: "reference",
                            type: "primary",
                            size: "small",
                          },
                          on: { click: _vm.ignoreUpdate },
                          slot: "reference",
                        },
                        [_vm._v("忽略更新 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.compareResultDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }