<template>
  <el-container style="background:#fff;height: auto;overflow: overlay;flex-direction: column;">
    <el-row style="padding: 20px;flex-grow: 1;overflow: hidden overlay;" v-loading="loading">
      <el-row>
        <el-col :span="24" class="inside-menu-wrap" :class="navBarFixed == true ? 'navBarWrap' :''" style="height: 40px;">
          <ul class="inside-menu">
            <li v-for="(item,index) in menulist" :key="index" :class="flag==index?'liActive':''"
                v-show="((item.href=='#a04' || item.href=='#a05' || item.href=='#a06') && drug.oligomict==='1' && resultNameOptions.length>0) ||(item.href!='#a04' && item.href!='#a05'&& item.href!='#a06')"
                @click="chooseMenu(index)">
              <a :href="item.href">{{item.name}}</a>
            </li>
          </ul>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="height: 40px;" v-show="navBarFixed == true">
        </el-col>
      </el-row>
      <el-col :span="24" class="title-bar" id="a01">药物基本信息</el-col>
      <el-form ref="form" status-icon :model="drug" label-width="120px">

        <el-form-item label="药物ID:"  v-show="drug.id">
          <el-input v-model="drug.id" :disabled="true" style="width: 300px;float: left"/>
        </el-form-item>
        <el-form-item
          label="中西药物标识"
          prop="classify"
          :rules="[{ required: true, message: '请输入中西药物标识', trigger: 'blur'}]">
          <el-select v-model="drug.classify" placeholder="请选择中西药物标识" style="width: 300px;float: left">
            <el-option
              v-for="item in cwMedicineIdentification"
              :key="item.conceptId"
              :label="item.conceptVal"
              :value="item.conceptId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否单成分药物:" prop="oligomict">
          <el-radio-group v-model="drug.oligomict" size="small" @change="oligomictChange">
            <el-radio-button label="0">否</el-radio-button>
            <el-radio-button label="1">是</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          v-for="(item, index) in drug.dkbDrugAliasList"
          :label="index==0?'药物名称:':''"
          :key="'drug-name-'+index"
          :rules="[{ required: true, message: '请输入药物名称', trigger: 'blur'},
          { validator: validateDrugName, trigger: 'blur'}]"
          :prop="'dkbDrugAliasList.' + index + '.alias'"
        >
          <el-input placeholder="请输入药物名称" v-model="item.alias" style="width: 450px;float: left"
                    maxlength="300" clearable show-word-limit
          />
          <el-select v-model="item.aliasLangue" placeholder="请选择语言" style="width: 80px;float: left">
            <el-option
              v-for="item in languageConcep"
              :key="item.conceptId"
              :label="item.conceptVal"
              :value="item.conceptId"
            ></el-option>
          </el-select>
          <el-form-item style="float: left;margin-bottom: 0px;"
                        :rules="[
          { required: true, message: '请选择名称类型', trigger: 'blur'},
           { validator: validateAliasType, trigger: 'blur'}
          ]"
                        :prop="'dkbDrugAliasList.' + index + '.aliasType'"
          >
            <el-select v-model="item.aliasType" placeholder="请选择类型" style="width: 120px;float: left">
              <el-option
                v-for="item in aliasTypeConcept"
                :key="item.conceptId"
                :label="item.conceptVal"
                :value="item.conceptId"
              ></el-option>
            </el-select>
          </el-form-item>

          <i class="el-icon-remove" @click.prevent="delSubstanceName(item)"></i>
          <el-radio v-model="drug.namePrimary" :label="index" border size="small"
                    @change="primaryChange($event,'name',index)"
                    style="margin-right: 10px;padding: 8px 8px 0 5px;"
          >
            首选项
          </el-radio>
          <el-button type="text" @click="showResourceTable('dkb_drug_alias','dkbDrugAliasList',index,'name')">添加来源</el-button>
          <el-button type="primary" plain class="addData" style="width: 80px;padding:0px 0px 0px 16px;"
                     v-if="index == drug.dkbDrugAliasList.length-1"
                     @click="addSubstanceName('中文')"
          >
            <i class="el-icon-circle-plus"
               style="color:#7C9DF7;width:16px;height:16px;display:inline-block;float: left"
            ></i>
            <svg-icon icon-class="china" style="width: 24px;height: 16px;"/>
          </el-button>
          <el-button type="primary" plain class="addData" style="width: 80px;padding:0px 0px 0px 16px;"
                     v-if="index == drug.dkbDrugAliasList.length-1"
                     @click="addSubstanceName('英语')"
          >
            <i class="el-icon-circle-plus"
               style="color:#7C9DF7;width:16px;height:16px;display:inline-block;float: left"
            ></i>
            <svg-icon icon-class="yingguoguoqi" style="width: 24px;height: 16px;"/>
          </el-button>
        </el-form-item>
        <el-form-item
          v-for="(item, index) in drug.dkbDrugDescList"
          :label="index==0?'药物描述:':''"
          :key="'drug-desc-'+index"
          :rules="[{ required: true, message: '请输入药物描述', trigger: 'blur'},
          { validator: validateDrugDesc, trigger: 'blur'}]"
          :prop="'dkbDrugDescList.' + index + '.drugDesc'"
        >
          <el-input class="subDescribe" type="textarea" :rows="6" placeholder="请输入药物描述" v-model="item.drugDesc"
                    maxlength="200" clearable show-word-limit
                    style="width: 500px;float: left;"
          />
          <i class="el-icon-remove" @click.prevent="delDrugDesc(item)"></i>
          <el-radio v-model="drug.descPrimary" :label="index" border @change="primaryChange($event,'desc',index)">
            设置为推荐值
          </el-radio>
          <el-button type="text" @click="showResourceTable('dkb_drug_desc','dkbDrugDescList',index,'drugDesc')">添加来源
          </el-button>
        </el-form-item>


        <el-form-item>
          <el-button type="primary" plain class="addData" style="width: 500px;"
                     @click="addDrugDesc"
          >
            <i class="el-icon-circle-plus el-icon--left" style="color:#7C9DF7;width:16px;height:16px;display:inline-block;"></i>
            添加药物描述
          </el-button>
        </el-form-item>



        <el-col :span="24" class="title-bar" id="a02">药物成分</el-col>
        <el-row>
          <el-col :span="22">
            <el-form ref="drugComponentForm" status-icon :model="drugComponent" label-width="120px">
              <el-form-item label="药物成分" prop="drugComponentQueryStr"
                            :rules="[{ required: true, message: '请输入查询内容', trigger: 'blur'},{ min: 1, max: 30, message: '查询内容长度在 1 到 30 个字符', trigger: 'blur' }]"
              >
                <el-select
                  v-model="drugComponent.drugComponentQueryStr"
                  filterable
                  remote
                  popper-class="select_component"
                  :remote-method="remoteMethod"
                  reserve-keyword
                  placeholder="输入物质名称"
                  >
                  <div class="clearfix table_Header">
                    <span style="width:100px;flex:1;">物质ID</span>
                    <span style="width:280px;flex:2;">物质名称</span>
                    <span style="width:280px;flex:2;">添加</span>
                  </div>
                  <el-option
                    v-for="(item, index) in resultNameOptions02"
                    :key="'substance'+item.substanceId+item.name"
                    :label="item.name"
                    :value="item.substanceId">
                    <div class="clearfix spanWrap">
                      <p :title="item.substanceId"
                        style="width:100px;flex:1;">{{ item.substanceId }}</p>
                      <p :title="item.name"
                        style="width:280px;flex:2;">{{ item.name }}</p>
                      <p style="width:280px;flex:2;color:#2B60F8;" @click="choosedrugComponentData(index, item)">添加</p>
                    </div>
                  </el-option>
                </el-select>
                 <el-button
                   icon="el-icon-circle-plus-outline"
                   style="margin-left: 20px;"
                   @click="addSubstance"
                   class="addClass"
                 >
                   新增物质
                </el-button>
                <el-button
                  v-show="drug.oligomict==='1'"
                  icon="el-icon-circle-plus-outline"
                  style="margin-left: 20px;"
                  @click="quickAddSubstance"
                  title="进入物质新增菜单并自动填充当前药物的名称和描述信息"
                  class="addClass"
                >
                  快捷新增物质
                </el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-row style="padding: 0px 0px 15px 15px;">
          <el-col :span="18">
            <el-table
              :data="resultNameOptions"
              :height="drugIngredientTableHeight"
              border
              stripe
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              ref="rno">
              <el-table-column property="substanceId" label="物质ID" width="150" sortable></el-table-column>
              <el-table-column property="substanceName" label="物质名称" sortable></el-table-column>
              <el-table-column property="" label="成分类型" width="250" >
                <template slot-scope="scope">
                  <el-select v-model="scope.row.substanceType" placeholder="请选择">
                    <el-option
                      v-for="item in drugComponentTypeOptions"
                      :key="item.conceptId"
                      :label="item.conceptVal"
                      :value="item.conceptId">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                  <el-button @click="deleteRow(scope.$index, resultNameOptions)" type="text" size="small" style="color: #FF3333;">删除</el-button>
                </template>
              </el-table-column>
              <template slot="empty">
                <img src="@/assets/images/no-booking.svg" alt="">
                <p>暂无数据</p>
              </template>
            </el-table>
          </el-col>
        </el-row>

        <el-col :span="24" class="title-bar" id="a03">药物组</el-col>
        <el-row>
          <el-col :span="22">
            <el-form ref="drugGroupQueryForm" status-icon :model="addDrugGroupForm" label-width="120px">
              <el-form-item label="所属药物组:" >
                <el-tag
                  style="height: 32px;padding: 0 10px;line-height: 30px;font-size: 14px;"
                  :key="item.drugGroupId+item.drugGroupName"
                  v-for="(item,index) in drug.dkbDrugGroupMappingList"
                  closable
                  :disable-transitions="false"
                  @close="handleRemoveDrugGroup(item,index)">
                  {{item.drugGroupName}}
                </el-tag>
                <el-select style="width: 200px;"
                           v-if="addDrugGroupInputVisible"
                           ref="saveTagInput"
                           class="input-new-tag"
                           v-model="addDrugGroupForm.id"
                           filterable
                           clearable
                           remote
                           reserve-keyword
                           placeholder="请输入关键字搜索"
                           :remote-method="selectDrugGroupList"
                           :loading="loading"
                           @change="handleSelectDrugGroup"
                           @blur="addDrugGroupInputBlur"
                           @visible-change="addDrugGroupInputBlur"
                >
                  <el-option
                    v-for="item in drugComponentTableData"
                    :key="item.id+'drugGroup'"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <el-button v-else icon="el-icon-link" class="button-new-tag addClass" size="small" @click="showDrugGroupInput">关联药物组</el-button>
                <el-button icon="el-icon-circle-plus-outline"
                           class="addClass"
                           size="small"
                           @click="addDrugGroup">
                  新增药物组
                </el-button>
              </el-form-item>
<!--              <el-form-item>
                <el-button icon="el-icon-circle-plus-outline"
                           class="addClass"
                           size="small"
                           @click="addDrugGroup">
                  新增药物组
                </el-button>
              </el-form-item>-->
            </el-form>
          </el-col>
        </el-row>



        <div v-if="drug.oligomict==='1' && resultNameOptions.length>0">
          <el-col :span="24" class="title-bar" id="a04">UNII物质信息</el-col>
          <el-row>
            <el-col :span="12" :offset="2" style="padding-bottom: 10px;">
              <el-button type="primary" icon="el-icon-search" style="background: #2B60F8"
                         @click="queryUnii"
              >UNII查询
              </el-button>
              <el-button type="warning" icon="el-icon-delete" plain
                         @click="clearUnii"
              >清除
              </el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2" style="height: 180px"></el-col>
            <el-col :span="3" style="height: 180px">
              <div style="width: 150px;height: 150px;background: #EBF0FA;border: 1px solid #BDD3FF;">
                <img src="@/assets/images/no-image.jpg" alt="image" style="width: 100%;height: 100%"
                     v-if="!drug.dkbSubstanceStructure.structuralPicture"
                >
                <img :src="'data:image/png;base64,'+drug.dkbSubstanceStructure.structuralPicture"
                     alt="image" style="width: 100%;height: 100%"
                     v-if="drug.dkbSubstanceStructure.structuralPicture"
                >
              </div>
            </el-col>
            <el-col :span="19" style="height: auto">
              <el-row>
                <el-col :span="10">
                  <el-form-item label="UNII:">
                    {{ substanceCode.unii }}
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="CAS:">
                    {{ substanceCode.cas }}
                    <i class="el-icon-edit cas-edit" v-show="substanceCode.unii && !substanceCode.cas"
                       @click="editCas(null,substanceCode.unii)"></i>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10">
                  <el-form-item label="立体化学:">
                    {{ drug.dkbSubstanceStructure.stereochemistry }}
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="分子式:">
                    {{ drug.dkbSubstanceStructure.molecularFormula }}
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="10">
                  <el-form-item label="旋光性:">
                    {{ drug.dkbSubstanceStructure.opticalActivity }}
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="分子量:">
                    {{ drug.dkbSubstanceStructure.molecularWeight }}
                  </el-form-item>
                </el-col>
              </el-row>


            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20" style="margin-left: 120px;">
              <el-form-item label="SMILES:">
                {{ drug.dkbSubstanceStructure.smiles }}
              </el-form-item>
            </el-col>
            <el-col :span="20" style="margin-left: 120px;">
              <el-form-item label="INCHI_KEY:">
                {{ drug.dkbSubstanceStructure.inchiKey }}
              </el-form-item>
            </el-col>
            <el-col :span="20" style="margin-left: 120px;">
              <el-form-item label="INCHI:">
                {{ drug.dkbSubstanceStructure.inchi }}
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div v-show="drug.oligomict==='1' && resultNameOptions.length>0">
          <el-col :span="24" class="title-bar" id="a05">物质属性</el-col>

          <el-form-item label="" prop="tableName" id="recommendProps">
            <el-button
              v-for="item in substanceProps"
              :key="'substancePropsButton-'+item.conceptId"
              plain
              @click="addSubstanceProp($event,item.conceptId)"
            >{{ item.conceptVal }}
            </el-button>

          </el-form-item>


          <el-col :span="24" style="margin-left: 80px;padding-bottom: 10px;font-size: 14px;">
            <div style="width: 200px;float: left;text-align: center">物质属性名称</div>
            <div style="width: 200px;float: left;text-align: center">物质属性值</div>
          </el-col>


          <el-col :span="18">


            <el-form-item
              v-for="(item, index) in drug.dkbSubstanceProps"
              :key="'substance-prop-'+index"
              :prop="'dkbSubstanceProps.' + index + '.name'"
              :rules="[
             { validator: validateSubstanceProps, trigger: 'blur'}
            ]"
            >
              <el-select v-model="item.name" placeholder="请选择物质属性名称"
                         style="width: 200px;float: left"
                         @change="propsNameSelectChange($event,index)"

              >
                <el-option
                  v-for="item in substanceProps"
                  :key="item.conceptId"
                  :label="item.conceptVal"
                  :value="item.conceptId"
                ></el-option>
              </el-select>
              <el-select v-model="item.value" placeholder="请选择物质属性值"
                         :disabled="item.name===null?true:false"
                         style="width: 200px;float: left"
                         filterable
                         :filter-method="((val)=>{substancePropsValFilter(val,drug.dkbSubstanceProps[index].name)})"
                         @focus=" substancePropsValOptions[drug.dkbSubstanceProps[index].name]= substancePropsVal[drug.dkbSubstanceProps[index].name]"
              >
                <el-option
                  v-for="item in substancePropsValOptions[drug.dkbSubstanceProps[index].name]"
                  :key="item.conceptId"
                  :label="item.conceptVal"
                  :value="item.conceptId"
                ></el-option>
              </el-select>
              <i class="el-icon-remove" @click.prevent="delSubstanceprop(item)"></i>
              <el-button type="text" @click="showResourceTable('dkb_substance_props','dkbSubstanceProps',index,'name')">
                添加来源
              </el-button>
            </el-form-item>


            <el-form-item>
              <el-button type="primary" plain class="addData" style="width: 400px;"
                         @click="addSubstanceProp"
              >
                <i class="el-icon-circle-plus el-icon--left"
                   style="color:#7C9DF7;width:16px;height:16px;display:inline-block;"
                ></i>
                添加
              </el-button>
            </el-form-item>
          </el-col>
        </div>

        <div v-show="drug.oligomict==='1' && resultNameOptions.length>0" >
          <el-col :span="24" class="title-bar" id="a06">关联物质</el-col>
          <el-col :span="20">
            <el-form-item label="关联物质:" prop="relevanceId" >
              <el-select style="width: 500px;"
                         v-model="drug.relevanceId"
                         filterable
                         clearable
                         remote
                         reserve-keyword
                         placeholder="请输入关键字搜索"
                         :remote-method="queryRelevanceSubstance"
                         :loading="loading"
              >
                <el-option
                  v-for="item in relevanceSubstanceList"
                  :key="item.substanceId+'relevanceSubstanceList'"
                  :label="item.name"
                  :value="item.substanceId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </div>

        <!-- <el-col :span="20" style="height: 80px;padding-right: 100px;position: fixed;right: 30px;bottom: 10px;">
          <el-button style="float: right;margin-left: 10px;" @click="editNext" v-show="drug.id">下一个</el-button>
          <el-button style="float: right;margin-left: 10px;" @click="editPrev" v-show="drug.id">上一个</el-button>
          <el-button style="float: right;margin-left: 10px;" @click="returnPage">返回</el-button>
          <el-button style="float: right;display: none" @click="resetForm">重置</el-button>
          <el-button type="primary" style="float: right" @click="submitForm(1)">保存</el-button>
          <el-button type="primary" style="float: right" @click="submitForm(2)">保存并关闭</el-button>
        </el-col> -->
      </el-form>
    </el-row>
    <el-row style="margin-bottom: 10px;">
      <el-col :span="20" class="bottom-bar" style="height: 80px;padding-right: 0px;position: fixed;right: 30px;bottom: 10px;">
        <el-button style="float: right;margin-left: 10px;" @click="editNext" v-show="drug.id">下一个</el-button>
        <el-button style="float: right;margin-left: 10px;" @click="editPrev" v-show="drug.id">上一个</el-button>
        <el-button style="float: right;margin-left: 10px;" @click="returnPage">返回</el-button>
        <el-button style="float: right;display: none" @click="resetForm">重置</el-button>
        <el-button type="primary" style="float: right" @click="submitForm(1)">保存</el-button>
        <el-button type="primary" style="float: right" @click="submitForm(2)">保存并关闭</el-button>
      </el-col>
    </el-row>
    <el-dialog v-dialogDrag title="引用来源管理" :visible.sync="resourceTableVisible"
               :close-on-click-modal="false" >
      <el-row>
        <el-col :span="24" style="margin-bottom: 10px;">
          <el-button type="primary" style="float: right;" icon="el-icon-circle-plus-outline"
                     size="small" @click="addResource"
          >添加
          </el-button>
        </el-col>
      </el-row>
      <el-form ref="resourceTableForm" status-icon :model="resourceTableData">
      <el-table
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :data="resourceTableData.list">
        <el-table-column property="date" label="引用资源类型" >
          <template slot-scope="scope">
            <el-form-item

              :prop="'list.' + scope.$index + '.'+'resourceTypeId'"
              :rules="[{ required: true, message: '请选择药物名称', trigger: 'blur'}]"
            >
            <el-select
              v-model="scope.row.resourceTypeId"
              placeholder="引用资源类型"
              @change="resourceTypeChange($event,scope.$index)"
              size="small"
              style="width: 200px"
            >
              <el-option
                v-for="dict in resourceTypeOption"
                :key="'resourceTypeOption'+dict.id"
                :label="dict.typeName"
                :value="dict.id"
              />
            </el-select>
            </el-form-item>

          </template>
        </el-table-column>
        <el-table-column property="name" label="引用资源名称" >
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.resourceId"
              filterable
              remote
              style="width: 300px"
              reserve-keyword
              placeholder="请输入关键词"
              @change="resourceNameChange($event,scope.$index)"
              :remote-method="(query)=>{resourceNameQuery(query,scope.row.resourceTypeId)}"
              :loading="loading"
              :disabled="!scope.row.resourceTypeId?true:false"
            >
              <el-option
                v-for="item in resourceDataOption"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
              <el-option
                v-for="item in [scope.row]"
                :key="item.resourceId+'original'"
                :label="item.resourceName"
                :value="item.resourceId"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="200">
          <template slot-scope="scope">

            <el-button
              size="mini"
              type="text"
              style="color: #FF3333;"
              @click="handleResourceDelete(scope.$index,scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
      </el-table>
      </el-form>
      <el-row>
        <el-col :span="24" style="margin-top: 10px;">
          <el-button type="primary" style="float: right;margin-right: 10px;" icon="el-icon-circle-check"
                     size="small" @click="saveResource"
          >确定
          </el-button>
          <el-button style="float: right;margin-right: 10px;" icon="el-icon-circle-close"
                     size="small" @click="resourceTableVisible=false"
          >取消
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog v-dialogDrag title="UNII物质检索" width="60%" :visible.sync="unii.uniiTableVisible" :close-on-click-modal="false">
      <el-form ref="uniiQueryForm" status-icon :model="unii.form">
        <el-form-item>
          <el-select v-model="unii.form.queryType" style="width: 120px;float: left"
                     title="搜索匹配方式仅适用于别名,unii和cas编码都是精确匹配">
            <el-option
              v-for="item in queryTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-input placeholder="请输入unii编码/cas编码/别名" v-model="unii.form.searchValue" maxlength="250" clearable
                    show-word-limit style="width: 400px;float: left" @keydown.enter.native="queryUnii"
          />
          <el-input style="display: none"/>
          <el-button type="primary" icon="el-icon-search" style="margin-left: 20px;background: #2B60F8"
                     @click="queryUnii"
          >检索
          </el-button>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="loading"
        :data="unii.uniiTableData"
        :height="300"
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
      >
        <el-table-column property="unii" label="unii编码" width="150"></el-table-column>
        <el-table-column property="cas" label="CAS" width="100"></el-table-column>
        <el-table-column property="displayName" label="名称"></el-table-column>
        <el-table-column property="displayName" label="别名">
          <template slot-scope="scope">
            <el-popover trigger="click" placement="top" width="400">
              <p v-for="(item,index) in scope.row.aliasList" :key="'unii'+scope.row.id+'_'+index">
                {{ item.name }}
              </p>
              <span slot="reference" class="name-wrapper">
                  <span style="color: #2B60F8;font-weight: 700;" title="点击查看全部别名">
                    {{ scope.row.aliasList ? scope.row.aliasList[0].name : '' }}
                  </span>
              </span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="chooseUniiData(scope.$index, scope.row)"
            >选择
            </el-button>
            <!--            type="primary" icon="el-icon-success"-->
          </template>
        </el-table-column>
        <template slot="empty" v-if="isUnii === false">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
      <pagination
        v-show="unii.total>0"
        :total="unii.total"
        :page.sync="unii.form.pageNum"
        :limit.sync="unii.form.pageSize"
        @pagination="queryUnii"
      />
    </el-dialog>

    <el-dialog v-dialogDrag title="差异比较" :visible.sync="compareResultDialogVisible"
               :close-on-click-modal="false" width="900px">

      <el-row>
        <el-col :span="12" >
          <el-col :span="24" class="title-bar">药物信息</el-col>
        </el-col>
        <el-col :span="12" >
          <el-col :span="24" class="title-bar">物质信息</el-col>
        </el-col>
      </el-row>

      <el-row style="height:400px;overflow: auto">
        <el-col :span="12" >
          <el-row>
            <el-col :span="24" >
              <el-card class="box-card" style="width: 400px;margin-bottom: 10px;">
                <div slot="header">
                  <span>药物名称</span>
                </div>
                <div  v-for="(item, index) in drug.dkbDrugAliasList" :key="'drug-name-'+index" style=" font-size: 14px;margin-bottom: 18px;">
                  <el-tag size="mini">{{languageConceptMap.get(item.aliasLangue)}}</el-tag>
                  <el-tag size="mini" style="margin-left: 6px;">{{aliasTypeConceptMap.get(item.aliasType)}}</el-tag>
                  <span> {{item.alias}}</span>
                  <el-tag type="info" size="mini" v-show="item.isPrimary=='1'">默认值</el-tag>
                  <!--                  <span>-&#45;&#45;d-n来源引用 :{{item.dkbReferenceInfoList}}</span>-->
                </div>
              </el-card>
              <el-card class="box-card" style="width: 400px;">
                <div slot="header">
                  <span>药物描述</span>
                </div>
                <div  v-for="(item, index) in drug.dkbDrugDescList" :key="'drug-desc-'+index" style=" font-size: 14px;margin-bottom: 18px;">
                  <span> {{item.drugDesc}}</span>
                  <el-tag type="info" size="mini" v-show="item.isPrimary=='1'">默认值</el-tag>
                  <!--                  <span>-&#45;&#45;d-d来源引用 :{{item.dkbReferenceInfoList}}</span>-->
                </div>
              </el-card>
            </el-col>

          </el-row>
        </el-col>
        <el-col :span="12" >
          <el-row>
            <el-col :span="24" >
              <el-card class="box-card" style="width: 400px;margin-bottom: 10px;">
                <div slot="header">
                  <span>物质名称</span>
                </div>
                <div  v-for="(item, index) in drug.dkbSubstanceName" :key="'substance-name-'+index" style=" font-size: 14px;margin-bottom: 18px;">
                  <el-tag size="mini">{{languageConceptMap.get(item.nameLangue)}}</el-tag>
                  <el-tag size="mini" style="margin-left: 6px;">{{aliasTypeConceptMap.get(item.aliasType)}}</el-tag>
                  <span> {{item.name}}</span>
                  <el-tag type="info" size="mini" v-show="item.isPrimary=='1'">默认值</el-tag>
                  <!--                  <span>-&#45;&#45;s-n来源引用 :{{item.dkbReferenceInfos}}</span>-->
                </div>
              </el-card>
              <el-card class="box-card" style="width: 400px;">
                <div slot="header">
                  <span>物质描述</span>
                </div>
                <div  v-for="(item, index) in drug.dkbSubstanceDesc" :key="'substance-desc-'+index" style=" font-size: 14px;margin-bottom: 18px;">
                  <span> {{item.substanceDesc}}</span>
                  <el-tag type="info" size="mini" v-show="item.isPrimary=='1'">默认值</el-tag>
                  <!--                  <span>-&#45;&#45;s-d来源引用 :{{item.dkbReferenceInfos}}</span>-->
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="margin-top: 10px;">
          <el-popover
            placement="top-start"
            title=""
            width="200"
            trigger="hover"
            content="使用当前药物的名称和描述信息，覆盖成分物质的名称和描述信息">
            <el-button slot="reference" type="primary" style="float: right;margin-right: 10px;"
                       size="small" @click="overrideUpdate"
            >覆盖更新
            </el-button>
          </el-popover>

          <el-popover
            placement="top-start"
            title=""
            width="200"
            trigger="hover"
            content="将当前药物和成分物质的名称和描述信息合并后更新">
            <el-button slot="reference" type="primary" style="float: right;margin-right: 10px;"
                       size="small" @click="mergeUpdate"
            >合并更新
            </el-button>
          </el-popover>

          <el-popover
            placement="top-start"
            title=""
            width="200"
            trigger="hover"
            content="忽略差异，药物的名称和描述信息直接保存，成分物质的名称和描述信息不做任何处理">
            <el-button slot="reference" type="primary" style="float: right;margin-right: 10px;"
                       size="small" @click="ignoreUpdate"
            >忽略更新
            </el-button>
          </el-popover>
          <el-button style="float: right;margin-right: 10px;"
                     size="small" @click="compareResultDialogVisible=false"
          >取消
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>

  </el-container>

</template>
<script>
import { selectDrugGroupNameList, selectSubstanceNameList, saveOrUpdate, info, list,checkFields ,oligomictDrugInfo} from '@/api/dkm/drug'
import { resourceNameQuery, saveOrUpdateInfoList, selectReferenceInfo } from '@/api/dkm/resource'
import { getResourceCategory } from '@/utils/resourceReference'
import { getConcept } from '@/utils/concept'
import conceptConst from '@/const/conceptConst'
import {DEEP_CLONE} from "../../../utils/constants";
import { isEmpty } from '@/utils/common'
import { info as substanceInfo ,list as substanceList,saveOrUpdate as saveOrUpdateSubstance,selectUniiOrNames,selectUniiRecord,
  updateCas} from '@/api/dkm/substance'
import { deepClone } from '../../../utils'
import $ from "jquery";
import '@/utils/nav.js'
import { setCache, getCache } from '@/utils/cacheUtil'
import AcrossUtil from '@/utils/acrossUtil'
export default {
  name: 'drugEdit',
  data() {
    return {
      conceptConst,
      drugGroupName: '',
      injuryAndPoisonCode: [],
      resultNameOptions: [], // 已选择药物成分list
      resultNameOptions02: [], // 已选择药物成分list
      drugComponentTableData: [],  // 已选择药物组list
      loading: false,
      /*新增药物时候，接口返回的id*/
      newInsertId:undefined,
      drug: {
        classify: 100401, // 中西草标识Id
        id: null, // 药物id
        oligomict:'1',//单成分药物,默认是
        dkbDrugAliasList: [], // 关联的药物别名
        dkbDrugDescList:[],//药物描述信息
        dkbDrugGroupMappingList: [], // 关联的药物组
        namePrimary: 0,
        descPrimary:0,
        dkbDrugIngredientList: [], // 关联的药物成分
        /*物质结构信息*/
        dkbSubstanceStructure: {
          id: null,
          substanceId: null,
          stereochemistry: '',
          opticalActivity: '',
          molecularFormula: '',
          molecularWeight: '',
          structuralPicture: '',
          inchi: '',
          inchiKey: '',
          smiles: '', molFile: '', mol3dFile: ''
        },
        /*物质代码信息*/
        dkbSubstanceCode: [],
        /*物质属性信息*/
        dkbSubstanceProps: [],
        /*物质名称信息*/
        dkbSubstanceName:[],
        /*物质描述信息*/
        dkbSubstanceDesc:[],
        /*关联物质id*/
        relevanceId:undefined
      },
      drugComponent: {
        /*药物成分检索输入框数据*/
        drugComponentQueryStr: null,
        /*药物成分检索表格数据*/
        drugComponentTableData: [],
        /*药物成分检索表格弹是否显示*/
        drugComponentTableVisible: false
      },
      /*药物类型下拉列表*/
      substanceTypeOptions: [],
      /*中西药标识符下拉列表 */
      cwMedicineIdentification: [],
      /**药物成分类型下拉列表 */
      drugComponentTypeOptions: [],
      /*名称类型下拉列表*/
      aliasTypeConcept: [],
      aliasTypeConceptMap:new Map(),
      /*语言概念下拉列表*/
      languageConcep: [],
      languageConceptMap:new Map(),
      resourceTypeOption: [{ id: 1, typeName: '说明书' }, { id: 2, typeName: '药典' }],
      resourceDataOption: [],
      /* 控制引用来源表格弹窗显示隐藏*/
      resourceTableVisible: false,
      /* 来源引用表格数据*/
      resourceTableData:{
        list:[]
      },
      /* 当前编辑来源引用弹窗临时记录信息*/
      nowEditResourceData: {
        sourceTable: null,
        sourceTableField: null,
        sourceTableId: null,
        fieldName:undefined,
        fieldIndex:undefined
      },

      flag: 0,
      menulist:[
        {
          href: '#a01',
          name: '药物基本信息'
        },
        {
          href: '#a02',
          name: '药物成分'
        },
        {
          href: '#a03',
          name: '药物组'
        },
        {
          href: '#a04',
          name: 'UNII物质信息'
        },
        {
          href: '#a05',
          name: '物质属性'
        },
        {
          href: '#a06',
          name: '关联物质'
        }
      ],
      navBarFixed: false,
      isUnii: false,
      unii: {
        /*unii检索数据*/
        form: {
          searchValue: undefined,
          queryType:'2',
          pageNum: 1,
          pageSize: 10
        },
        /*总条数*/
        total: 0,
        /*unii检索表格数据*/
        uniiTableData: [],
        /*unii检索表格弹是否显示*/
        uniiTableVisible: false
      },
      queryTypeOptions:[
        {value: '2', label: '精确匹配'},
        {value: '1', label: '模糊匹配'},
        {value: '3', label: '前缀匹配'}
      ],
      /*物质属性名称概念字典*/
      substanceProps: [],
      /*物质属性值概念字典*/
      substancePropsVal: {},
      substancePropsValOptions: {},
      /* 控制物质和药物信息比较结果弹窗显示隐藏*/
      compareResultDialogVisible: false,
      /*名称比较结果*/
      nameMergeResult:new Map(),
      /*描述比较结果*/
      descMergeResult:new Map(),
      /*记录名称和描述的首选项key*/
      primaryKey:{
        substanceName:undefined,
        substanceDesc:undefined,
        drugName:undefined,
        drugDesc:undefined,
      },
      /*关联物质下拉列表*/
      relevanceSubstanceList:[],
      /*列表页面查询参数*/
      queryParams:{},
      /*存储根据查询获取到的数据，用于翻页*/
      pageData:[],
      /*1保存2保存并关闭*/
      submitType:1,
      /*如果是从物质管理页面过来的，记录单成分药物，原始的物质id*/
      originalSubstanceId:undefined,
      /*新增药物组输入框*/
      addDrugGroupInputVisible:false,
      addDrugGroupForm:{
        id:undefined,
        name:undefined
      },


    }
  },
  filters: {
    statusChoice(val) {
      if (val === '0') {
        return '启用'
      } else {
        return '停用'
      }
    }
  },
  mounted: function() {
    // 事件监听滚动条
    window.addEventListener('scroll', this.watchScroll)
  },
  created() {
    this.loading=true
    //获取引用资源类型
    getResourceCategory().then(result => {
      this.resourceTypeOption = result
    })

    const id = this.$route.params && this.$route.params.id
    if (id) {
      if(this.$route.path.indexOf("editOligomictDrug")>0){
        //编辑单成分药物
        this.queryParams=getCache("substanceQueryParams")
        this.pageData=getCache("substancePageData")
      }else{
        this.queryParams=getCache("drugQueryParams")
        this.pageData=getCache("drugPageData")
      }

      if(!this.queryParams){
        this.queryParams={}
      }
      if(!this.queryParams.pageNum){
        this.queryParams.pageNum=1
      }
      if(!this.queryParams.pageSize){
        this.queryParams.pageSize=10
      }
      if (this.copyType) { // 复制数据
        this.init().then(result => {
          this.getDrugInfo_cope(id)
          this.loading=false
        })
      } else {
        this.init().then(result => {
          this.getDrugInfo(id)
        })
      }

    } else {
      this.init().then(result=>{
        this.addSubstanceName('中文')
        this.loading=false
      })


    }
    // this.scrollFn()
  },
  computed: {
    copyType() {
      return this.$route.query.copyType
    },
    /*计算属性--获取cas编码和unii编码*/
    substanceCode() {
      let code = {}
      this.drug.dkbSubstanceCode.some((item, i) => {
        if (item.codeSysName === 'unii') {
          code.unii = item.substanceCode
        }
        if (item.codeSysName === 'cas') {
          code.cas = item.substanceCode
        }
        if (code.unii && code.cas) {
          return true
        }

      })
      return code
    },
    /* 计算属性--药物成分表格高度*/
    drugIngredientTableHeight() {
      let dkbDrugIngredientList = this.resultNameOptions
      let row = dkbDrugIngredientList ? dkbDrugIngredientList.length : 0
      let height = 0
      height = 150 + (row - 1) * 50
      if (height < 150) {
        return 150
      } else if (height > 400) {
        return 400
      } else {
        return height
      }
    }
  },
  methods: {
    watchScroll () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log(scrollTop)
      //  当滚动超过 50 时，实现吸顶效果
      if (scrollTop > 40) {
        this.navBarFixed = true
      } else {
        this.navBarFixed = false
      }
    },
    chooseMenu(index) {
      this.flag = index
    },
    scrollFn() {
      $(document).ready(function () {
        // var navHeight = $(".inside-menu-wrap").offset().top;
        // console.log(navHeight)
        var navHeight = $(".inside-menu-wrap").offset().top + 40;
        // console.log(navHeight)
        var navFix=$(".inside-menu-wrap");
        $(window).scroll(function () {
          if ($(this).scrollTop() >= navHeight) {
            // console.log($(this).scrollTop())
            navFix.addClass("menuFix");
          } else {
            navFix.removeClass("menuFix");
            $(".inside-menu li a.active").removeClass("active")
          }
        })
      })

      //内容信息导航锚点
      $('.inside-menu-wrap').navScroll({
        scrollSpy: true
      });
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
      //如果是单成分药物---同步删除物质unii信息和物质属性
      if(this.drug.oligomict==='1'){
        this.drug.dkbSubstanceStructure={}
        this.drug.dkbSubstanceCode=[]
        this.drug.dkbSubstanceProps=[]
        this.drug.dkbSubstanceName=[]
        this.drug.dkbSubstanceDesc=[]
        this.drug.relevanceId=undefined
        this.drug.relevanceName=undefined
      }
    },
    /*初始化--加载字典等*/
    async init() {
      //获取中西药物标识数据字典
      await getConcept(conceptConst.DKB_DRUG_CLASSIFY, 0).then(result => {
        this.cwMedicineIdentification = result
      })
      /*获取语言概念字典*/
      await getConcept(conceptConst.PUB_LANG, 0).then(result => {
        this.languageConcep = result
        this.languageConcep.forEach(item=>{
          this.languageConceptMap.set(item.conceptId,item.conceptVal)
        })
      })
      /*获取药物属性概念字典*/
      await getConcept(conceptConst.DKB_SUBSTANCE_PROPS, 1).then(result => {
        this.substanceProps = result
        let propsVal = this.substancePropsVal
        result.forEach(function(item, index) {
          propsVal[item.conceptId] = item.sonDictTermConcept
        })
      })
      /*获取药物成分类型字典*/
      await getConcept(conceptConst.DKB_DRUG_INGREDIENT_TYPE, 0).then(result => {
        this.drugComponentTypeOptions = result
      })

      /*获取名称类型字典*/
      await getConcept(conceptConst.PUB_NAME_TYPE, 0).then(result => {
        this.aliasTypeConcept = result
        this.aliasTypeConcept.forEach(item=>{
          this.aliasTypeConceptMap.set(item.conceptId,item.conceptVal)
        })
      })

      /*获取物质属性概念字典*/
      await getConcept(conceptConst.DKB_SUBSTANCE_PROPS, 1).then(result => {
        this.substanceProps = result
        let that = this
        result.forEach(function(item, index) {
          that.substancePropsVal[item.conceptId] = item.sonDictTermConcept
          // 在JS中操作原本不存在的属性时，使用 Vue.set(object, propertyName, value) 方法向嵌套对象添加响应式属性
          that.$set(that.substancePropsValOptions, item.conceptId, item.sonDictTermConcept)
        })
      })
      /*获取物质类型字典*/
      await getConcept(conceptConst.DKB_SUBSTANCE_TYPE, 0).then(result => {
        this.substanceTypeOptions = result
      })
    },
    /*添加药物名称输入表单组件*/
    addSubstanceName(type) {
      let defaultVal
      if (this.languageConcep && this.languageConcep.length > 0) {
        this.languageConcep.forEach(item=>{
          if(item.conceptVal==type){
            defaultVal=item.conceptId
          }
        })
      }
      this.drug.dkbDrugAliasList.push({
        value: '',
        aliasLangue: defaultVal,
        aliasType: type=='英语'?conceptConst.PUB_NAME_TYPE_EN:conceptConst.PUB_NAME_TYPE_GENERIC,
        dkbReferenceInfoList:[],
        isPrimary: this.drug.dkbDrugAliasList.length == 0 ? '1' : '0'
      })
      if (this.drug.dkbDrugAliasList.length == 1) {
        //添加组件时，默认第一个选中为默认
        this.drug.namePrimary = 0
      }

    },
    /*移除药物名称输入表单组件*/
    delSubstanceName(item) {
      let index = this.drug.dkbDrugAliasList.indexOf(item)
      if (this.drug.dkbDrugAliasList.length > 1) {
        this.drug.dkbDrugAliasList.splice(index, 1)
      } else {
        this.$message('药物名称至少保留一个！')
      }
      // 删除后如果没有默认值了，取第一个作为默认值
      let primary = -1
      this.drug.dkbDrugAliasList.forEach(function(item, index) {
        if (item.isPrimary === '1') {
          primary = index
          return
        }
      })
      if (primary === -1) {
        //删除后没有默认值了
        this.drug.dkbDrugAliasList[0].isPrimary = '1'
        this.drug.namePrimary = 0
      }

    },
    /*添加药物描述输入表单组件*/
    addDrugDesc(){
      this.drug.dkbDrugDescList.push({
        drugDesc: '',
        dkbReferenceInfoList:[],
        isPrimary: this.drug.dkbDrugDescList.length == 0 ? '1' : '0'
      })
      if (this.drug.dkbDrugDescList.length == 1) {
        //添加组件时，默认第一个选中为默认
        this.drug.descPrimary = 0
      }
    },
    /*移除药物描述输入表单组件*/
    delDrugDesc(item) {
      var index = this.drug.dkbDrugDescList.indexOf(item)
      this.drug.dkbDrugDescList.splice(index, 1)
      // 删除后如果没有默认值了，取第一个作为默认值
      let primary = -1
      this.drug.dkbDrugDescList.forEach(function(item, index) {
        if (item.isPrimary === '1') {
          primary = index
          return
        }
      })
      if (primary === -1 && this.drug.dkbDrugDescList.length > 0) {
        //删除后没有默认值了
        this.drug.dkbDrugDescList[0].isPrimary = '1'
        this.drug.descPrimary = 0
      }
    },

    async doSave() {
      let substanceIdArray = []
      this.resultNameOptions.forEach((item) => {
        substanceIdArray.push(item.substanceId)
      })
      let fieldValue = ''
      fieldValue = substanceIdArray.toString()
      let item
      if (!isEmpty(fieldValue)) {
        let obj = {}
        obj.fieldName = 'substance_id'
        obj.fieldValue = fieldValue
        obj.drugId = this.drug.id
        obj.classify = this.drug.classify
        let response = await checkFields(obj)
        item = response.data && response.data !== null ? response.data : undefined
      }

      if (item && this.drug.oligomict == '0') {
        // 不是单成分药物，且存在同成分药物,允许保存
        this.$confirm('已存在同成分药物[' + item.alias + ']！确定保存？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log("then")
          this.doSave2(fieldValue)
        }).catch(() => {
          return
        });
      }else if(item && this.drug.oligomict == '1'){
        // 是单成分药物，且存在同成分药物,不允许保存
        this.msgError('保存失败:已存在同成分的单成分药物[' + item.alias + ']！设置为非单成分药物后才可以保存');
      }else{
        this.doSave2(fieldValue)
      }



    },
    doSave2(fieldValue){
      this.drug.dkbDrugIngredientList = this.resultNameOptions
      if (this.drug.oligomict == '0') {
        //不是单成分药物，直接保存
        this.ignoreUpdate()
      } else {
        if (isEmpty(fieldValue)){
          //设置单成分药物，且没有成分物质，不能保存
          this.msgError('保存失败:单成分药物必须要有一个成分物质');
          return
        }
        //单成分药物，需要差异比较
        //差异比较结果
        this.compareWithSubstance()
      }
    },
    /** 保存按钮--新增药物 */
    submitForm(type) {
      this.submitType=type
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.resultNameOptions.length === 0) {
            this.$confirm('药物成分为空，是否仍确认保存？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.doSave()
            }).catch(() => {
              return
            });
          }else{
            this.doSave()
          }
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    /*单成分药物--根据药物信息对物质信息做处理*/
    handleSubstanceData(){
      this.drug.dkbSubstance={}
      let dkbSubstance= this.drug.dkbSubstance
      if(this.drug.classify==100401){
        //药物:西药-->物质:化学品
        dkbSubstance.type=100201
      }else if(this.drug.classify==100403){
        //药物:草药-->物质:结构多样性材料
        dkbSubstance.type=100205
      }
      if(this.drug.dkbDrugIngredientList && this.drug.dkbDrugIngredientList.length>0){
        dkbSubstance.id= this.drug.dkbDrugIngredientList[0].substanceId
      }
      dkbSubstance.dkbSubstanceName=[]
      dkbSubstance.dkbSubstanceDesc=[]
      //根据药物别名填充物质别名
      this.drug.dkbDrugAliasList.forEach(item=>{
        dkbSubstance.dkbSubstanceName.push({
          name:item.alias,
          isPrimary:item.isPrimary,
          aliasType:item.aliasType,
          dkbReferenceInfos:item.dkbReferenceInfoList,
          nameLangue:item.aliasLangue
        })
      })

      //根据药物描述填充物质描述
      this.drug.dkbDrugDescList.forEach(item=>{
        dkbSubstance.dkbSubstanceDesc.push({
          substanceDesc:item.drugDesc,
          isPrimary:item.isPrimary,
          dkbReferenceInfos:item.dkbReferenceInfoList,
        })
      })
      dkbSubstance.dkbSubstanceCode=this.drug.dkbSubstanceCode
      dkbSubstance.dkbSubstanceStructure=this.drug.dkbSubstanceStructure||{}
      dkbSubstance.dkbSubstanceProps=this.drug.dkbSubstanceProps
      dkbSubstance.relevanceId=this.drug.relevanceId
      dkbSubstance.relevanceName=this.drug.relevanceName
    },
    /* 药物描述-页面重复性校验*/
    validateDrugDesc(rule, value, callback, index) {
      if (!isEmpty(value)) {
        // 页面表单校验，
        let dkbDrugDesc = this.drug.dkbDrugDescList
        let key = Object.keys(index)[0]
        let nameIndex = key.replaceAll('dkbDrugDescList.', '').replaceAll('.drugDesc', '')
        if (dkbDrugDesc) {
          dkbDrugDesc.forEach(function(j, i) {
            if (i != nameIndex && j.drugDesc && j.drugDesc.trim() === value.trim()) {
              callback(new Error('药物描述已存在'))
            }
          })
        }
        callback()
      } else {
        callback()
      }
    },
    /* 药物名称-重复性校验*/
    validateDrugName(rule, value, callback,index){
      this.loading=false
      if(!isEmpty(value)){
        // 调用接口校验
        let obj = {}
        obj.fieldName = 'alias'
        obj.fieldValue = value
        obj.drugId = this.drug.id
        obj.classify = this.drug.classify
        checkFields(obj).then(response => {
          let item=response.data && response.data !== null ? response.data:undefined
          if(item){
            callback(new Error('药物名称已存在,药物id为['+item.drugId+']'));
          }else{
            // 页面表单校验，
            let dkbDrugAliasList = this.drug.dkbDrugAliasList
            let key = Object.keys(index)[0]
            let nameIndex = key.replaceAll('dkbDrugAliasList.', '').replaceAll('.alias', '')
            let nowName=dkbDrugAliasList[nameIndex]
            if (dkbDrugAliasList) {
              dkbDrugAliasList.forEach(function(j, i) {
                //名称+名称语言+名称类型完全一样算重复
                if (i != nameIndex && j.alias && j.alias.trim() === value.trim() && j.aliasLangue===nowName.aliasLangue && j.aliasType===nowName.aliasType) {
                  callback(new Error('药物名称已存在'))
                }
              })
            }

            callback()
          }
        })
      }else{
        callback();
      }
    },
    /* 名称类型-校验*/
    validateAliasType(rule, value, callback, index) {
      this.loading=false
      if (!isEmpty(value)) {
        let allName=this.drug.dkbDrugAliasList
        let englishNameSize=0
        let genericNameSize=0
        allName.forEach(function(item, i) {
          if(item.aliasType==conceptConst.PUB_NAME_TYPE_EN){
            englishNameSize++
          } else if(item.aliasType==conceptConst.PUB_NAME_TYPE_GENERIC){
            genericNameSize++
          }
        })
        if(englishNameSize>1){
          callback(new Error('英文名只能有一个'))
        }else if(genericNameSize>1){
          callback(new Error('通用名只能有一个'))
        }else{
          callback()
        }

      }else {
        callback()
      }

    },
    /*返回药物列表页面*/
    returnPage() {
      const id = this.$route.params && this.$route.params.id
      if (id) {
        if (this.copyType) { // 复制数据
          this.init().then(result => {
            this.getDrugInfo_cope(id)
          })
        } else {
          /*this.init().then(result => {
            this.getDrugInfo(id)
          })*/
        }
      } else {
        //this.clearData()
      }
      //调用刷新表格数据的方法
      AcrossUtil.$emit('tableReload');
      if(this.submitType==2){
        this.$store.dispatch('tagsView/delView', this.$route)
        this.$router.push('/dkm/drug')
      }else{
        //保存完成后根据id跳转到编辑界面
        if(this.newInsertId){
          //是否是编辑单物质药物
          let editOligomictDrug=this.$route.path.indexOf("editOligomictDrug")>0?true:false
          let fullPath
          if(editOligomictDrug){
            fullPath='/redirect' + '/drug/editOligomictDrug/' + this.newInsertId
          }else{
            fullPath='/redirect' + '/drug/edit/' + this.newInsertId
          }
          this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
            //this.$router.push('/drug/edit/' + this.newInsertId)
            this.$nextTick(() => {
              this.$router.replace({
                path: fullPath
              })
            })
          })
        }else{
          this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
            this.$router.push({ path: '/dkm/drug' })
          })
        }
      }

    },
    /*clearData() {
      this.$nextTick(() => {
        if (this.$refs.form !== undefined) {
          this.$refs.form.resetFields()
        }
        if (this.$refs.drugComponentForm !== undefined) {
          this.$refs.drugComponentForm.resetFields()
        }
        if (this.$refs.drugGroupQueryForm !== undefined) {
          this.$refs.drugGroupQueryForm.resetFields()
        }
        if (this.$refs.resourceTableForm !== undefined) {
          this.$refs.resourceTableForm.resetFields()
        }
        this.resultNameOptions = [] // 已选择药物成分list
        this.drug = {
          classify: 100401, // 中西草标识Id
          id: null, // 药物id
          dkbDrugAliasList: [], // 关联的药物别名
          dkbDrugGroupMappingList: [], // 关联的药物组
          namePrimary: 0,
          dkbDrugIngredientList: [], // 关联的药物成分
        }
        this.addSubstanceName()
      })
    },*/
    /*根据药物id获取药物明细*/
    getDrugInfo(id) {
      // let that = this
      this.loading=true
      info(id).then(response => {
        this.drug.id = response.data.id
        this.drug.oligomict = response.data.oligomict
        this.drug.classify = response.data.classify
        this.drug.dkbDrugAliasList = response.data.dkbDrugAliasList
        this.drug.dkbDrugDescList = response.data.dkbDrugDescList
        this.drug.dkbDrugGroupMappingList = response.data.dkbDrugGroupMappingList
        this.drug.dkbDrugIngredientList = response.data.dkbDrugIngredientList
        this.resultNameOptions = this.drug.dkbDrugIngredientList
        /*设置首选项单选按钮*/
        this.setPrimary('name')
        //填充单成分药物关联的物质信息
        if(this.drug.oligomict==='1'){
          if(response.data.dkbSubstance){
            this.drug.dkbSubstanceStructure= response.data.dkbSubstance.dkbSubstanceStructure||{}
            this.drug.dkbSubstanceCode= response.data.dkbSubstance.dkbSubstanceCode
            this.drug.dkbSubstanceProps= response.data.dkbSubstance.dkbSubstanceProps
            this.drug.dkbSubstanceName= response.data.dkbSubstance.dkbSubstanceName
            this.drug.dkbSubstanceDesc= response.data.dkbSubstance.dkbSubstanceDesc
            this.drug.relevanceId=response.data.dkbSubstance.relevanceId
            this.drug.relevanceName = response.data.dkbSubstance.relevanceName
            //记录原始的物质id，上一条下一条需要使用
            this.originalSubstanceId=response.data.dkbSubstance.id
            if(this.drug.relevanceId){
              this.relevanceSubstanceList.push({substanceId:this.drug.relevanceId,name:this.drug.relevanceName})
            }
          }


        }
        this.loading=false
      })
    },
    getDrugInfo_cope(id) {
      // let that = this
      info(id).then(response => {
        let copyData = DEEP_CLONE(response.data)
        copyData.id = ''
        this.drug.id = copyData.id
        this.drug.classify = copyData.classify
        if (copyData.dkbDrugAliasList.length !== 0){
          copyData.dkbDrugAliasList.forEach((item) =>{
            item.id = ''
            item.drugId = ''
          })
        }
        if (copyData.dkbDrugGroupMappingList.length !== 0){
          copyData.dkbDrugGroupMappingList.forEach((item) =>{
            item.id = ''
            item.drugId = ''
          })
        }
        if (copyData.dkbDrugIngredientList.length !== 0){
          copyData.dkbDrugIngredientList.forEach((item) =>{
            item.id = ''
            item.drugId = ''
          })
        }
        this.drug.dkbDrugAliasList = copyData.dkbDrugAliasList
        this.drug.dkbDrugGroupMappingList = copyData.dkbDrugGroupMappingList
        this.drug.dkbDrugIngredientList = copyData.dkbDrugIngredientList
        this.resultNameOptions = this.drug.dkbDrugIngredientList
        /*设置首选项单选按钮*/
        this.setPrimary('name')
      })
    },
    remoteMethod(val) {
      if (val && val.trim() !== '') {
        let obj = {}
        obj.queryStr = val
        selectSubstanceNameList(obj).then(response => {
          this.resultNameOptions02 = response.data
        })
      }
    },
    selectDrugGroupList(val) {
      if (val && val.trim() !== '') {
        let obj = {}
        obj.queryStr = val
        selectDrugGroupNameList(obj).then(response => {
          this.drugComponentTableData = response.data
        })
      }
    },
    /*药物成分检索*/
    queryDrugComponent() {
      this.$refs['drugComponentForm'].validate(valid => {
        if (valid) {
          let obj = {}
          obj.queryStr = this.drugComponent.drugComponentQueryStr
          this.drugComponent.drugComponentTableVisible = true
          selectSubstanceNameList(obj).then(response => {
            this.drugComponent.drugComponentTableData = response.data
            this.loading = false
          })
        } else {
        }
      })
    },
     /*药品成分检索表格，选中数据*/
    choosedrugComponentData(index, row) {
      if(this.drug.oligomict==='1' && this.resultNameOptions.length>0){
        this.msgError('单成分药物只能添加一个物质成分！')
        return;
      }
      const item = this.resultNameOptions.find(item => item.substanceId === row.substanceId)
      if (item) {
        this.msgError('物质不能重复选择，请重新添加！')
        return false
      } else {
        let drugComponentObj = {}
        drugComponentObj.substanceName = row.name
        drugComponentObj.substanceId = row.substanceId
        drugComponentObj.substanceType = 100501
        // drugComponentArr.push(drugComponentObj)
        this.resultNameOptions.push(drugComponentObj)
        this.$nextTick(() => {
          this.$refs.rno.doLayout()
        })
        //单成分药物---根据物质id查询物质详细信息
        if(this.drug.oligomict==='1'){
          //获取物质信息
          substanceInfo(row.substanceId).then(response => {
            this.drug.dkbSubstanceStructure=response.data.dkbSubstanceStructure||{}
            this.drug.dkbSubstanceCode=response.data.dkbSubstanceCode
            this.drug.dkbSubstanceProps=response.data.dkbSubstanceProps
            this.drug.dkbSubstanceName= response.data.dkbSubstanceName
            this.drug.dkbSubstanceDesc= response.data.dkbSubstanceDesc
            this.drug.relevanceId=response.data.relevanceId
            this.drug.relevanceName = response.data.relevanceName
            if(this.drug.relevanceId){
              this.relevanceSubstanceList.push({substanceId:this.drug.relevanceId,name:this.drug.relevanceName})
            }
          })
        }
      }
    },
    /*设置首选项单选按钮*/
    setPrimary(type) {
      let field = type === 'name' ? 'dkbDrugAliasList' : 'dkbSubstanceDesc'
      let primaryField = type === 'name' ? 'namePrimary' : 'descPrimary'
      let drug = this.drug
      if (drug[field] !== undefined) {
        drug[field].forEach(function(item, index) {
          if (item.isPrimary === '1') {
            drug[primaryField] = index
            return
          }
        })
      }
    },
    primaryChange(value, type, index) {
      let field = type === 'name' ? 'dkbDrugAliasList' : 'dkbDrugDescList'
      let primaryField = type === 'name' ? 'namePrimary' : 'descPrimary'
      this.drug[primaryField] = index
      this.drug[field].forEach(function(item, index) {
        if (index === value) {
          item.isPrimary = '1'
        } else {
          item.isPrimary = '0'
        }
      })
    },
    /*显示来源引用表格*/
    showResourceTable(tableName, fieldName,index, field) {

      let row= this.drug[fieldName][index]
      this.resourceTableData.list=[]
      this.nowEditResourceData.fieldName=fieldName
      this.nowEditResourceData.fieldIndex=index
      this.nowEditResourceData.sourceTable = tableName
      this.nowEditResourceData.sourceTableField = field
      this.nowEditResourceData.sourceTableId = row.id
      Object.assign(this.resourceTableData.list, row.dkbReferenceInfoList||[])
      this.resourceTableVisible = true
    },
    /*新增一条引用来源*/
    addResource() {
      this.resourceTableData.list.push({
        sourceTable: this.nowEditResourceData.sourceTable,
        sourceTableField: this.nowEditResourceData.sourceTableField,
        sourceTableId: this.nowEditResourceData.sourceTableId,
        resourceTypeId: null,
        resourceId: null
      })
    },
    resourceNameQuery(query, resourceTypeId) {
      if (!isEmpty(query)) {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          resourceNameQuery(resourceTypeId, query.trim()).then(response => {
            this.resourceDataOption = response.data

          })
        }, 200)
      } else {
        this.options = []
      }
    },
    /*处理来源引用表格删除*/
    handleResourceDelete(index, row) {
      this.resourceTableData.list.splice(index, 1)

    },
    /*来源引用表格-资源类型下拉列表变化*/
    resourceTypeChange(value, tableRowIndex) {
      this.resourceDataOption=[];
      let item=this.resourceTableData.list[tableRowIndex];
      item.resourceId=undefined
      item.resourceName=undefined
    },
    /*来源引用表格-资源名称下拉列表变化*/
    resourceNameChange(value, tableRowIndex) {
      let obj = {}
      obj = this.resourceDataOption.find((item) => {
        return item.id === value
      })
      this.resourceTableData.list[tableRowIndex].resourceId = obj.id
      this.resourceTableData.list[tableRowIndex].resourceName = obj.name
      this.resourceDataOption = []
    },
    /* 保存来源引用*/
    saveResource() {
      this.$refs['resourceTableForm'].validate(valid => {
        if (valid) {

          let fieldName= this.nowEditResourceData.fieldName
          let index=this.nowEditResourceData.fieldIndex
          let list=this.drug[fieldName][index].dkbReferenceInfos
          this.$set(this.drug[fieldName][index],'dkbReferenceInfoList', this.resourceTableData.list)
          this.resourceTableVisible = false
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    /*重置表单*/
    resetForm(formName) {
      let form = this.$refs[formName]
      if (form) {
        form.resetFields()
      }
    },
    // 新增物质
    addSubstance() {
      this.$router.push('/substance/edit/')
    },
    // 快捷新增物质
    quickAddSubstance() {
      if(this.resultNameOptions.length>0){
        this.msgError('快捷新增失败:当前药物已包含有成分物质')
        return;
      }
      //药品表单校验
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.handleSubstanceData()
          //保存物质信息
          saveOrUpdateSubstance(this.drug.dkbSubstance).then(response => {
            if(response.code!=200){
              this.msgError('快捷新增物质失败!'+(response.msg||''))
              return;
            }
            let substanceId=response.data
            let subStanceName=''
            this.drug.dkbDrugAliasList.forEach(item=>{
              if(item.isPrimary=='1'){
                subStanceName=item.alias
              }
            })
            let row={
              substanceId:substanceId,
              name:subStanceName
            }
            //将新增的物质填充到药物成分列表
            this.choosedrugComponentData(0,row)
            //如果有英文名，则查询填充unii信息
            this.autoFillUnii()
          })

        }else{
          this.msgError('请规范填写')
        }
      })
      //this.$refs['form'].validateField("dkbDrugAliasList.0.alias")
      this.handleSubstanceData()


      /*this.$router.push({
        path:'/substance/edit/',
        query: {
          dkbDrugAliasList: this.drug.dkbDrugAliasList,
          dkbDrugDescList:this.drug.dkbDrugDescList
        }
      })*/
    },
    // 新增药物组
    addDrugGroup() {
      this.$router.push('/drugGroup/edit/')
    },
    /*是否单成分药物,值变化*/
    oligomictChange(s){
     if(s==='1' && this.resultNameOptions.length>1){
       this.msgError('该药物含有多个成分，无法设置为单成分药物!')
       this.drug.oligomict='0'
     }else{
       this.drug.oligomict=s
     }

      //单成分药物---根据物质id查询物质详细信息
      if(this.drug.oligomict==='1' && this.resultNameOptions.length>0){
        //获取物质信息
        substanceInfo(this.resultNameOptions[0].substanceId).then(response => {
          this.drug.dkbSubstanceStructure=response.data.dkbSubstanceStructure||{}
          this.drug.dkbSubstanceCode=response.data.dkbSubstanceCode
          this.drug.dkbSubstanceProps=response.data.dkbSubstanceProps
          this.drug.dkbSubstanceName= response.data.dkbSubstanceName
          this.drug.dkbSubstanceDesc= response.data.dkbSubstanceDesc
          this.drug.relevanceId=response.data.relevanceId
          this.drug.relevanceName = response.data.relevanceName
          if(this.drug.relevanceId){
            this.relevanceSubstanceList.push({substanceId:this.drug.relevanceId,name:this.drug.relevanceName})
          }

        })
      }
    },



    //清理UNii信息
    clearUnii(){
      this.drug.dkbSubstanceStructure={}
      this.drug.dkbSubstanceCode = []
    },

    /*unii查询*/
    queryUnii() {
      this.isUnii = true
      this.loading = true
      this.unii.uniiTableVisible = true
      selectUniiOrNames(this.unii.form).then(response => {
        this.unii.uniiTableData = response.rows
        this.unii.uniiTableData.forEach(item => {
          if (item.aliasList && item.aliasList.length > 0) {
            item.displayName = item.aliasList[0].displayName
          }
        })
        this.unii.total = response.total
        this.loading = false
      })
    },
    /*unii检索表格，选中数据*/
    chooseUniiData(index, row) {
      //根据unii查询物质结构信息
      selectUniiRecord(row.unii).then(response => {
        this.unii.uniiTableVisible = false
        if (response.data.length > 0) {
          let row = response.data[0]
          let unii = this.drug.dkbSubstanceStructure
          unii.stereochemistry = row.stereochemistry
          unii.opticalActivity = row.opticalActivity
          unii.molecularFormula = row.molecularFormula
          unii.molecularWeight = row.molecularWeight
          unii.structuralPicture = row.structuralPicture
          unii.inchi = row.inchi
          unii.inchiKey = row.inchiKey
          unii.smiles = row.smiles
          unii.molFile = row.molFile
          unii.mol3dFile = row.mol3dFile
          this.drug.dkbSubstanceCode = []
          this.drug.dkbSubstanceCode.push({ codeSysId: '', codeSysName: 'unii', substanceCode: row.unii })
          this.drug.dkbSubstanceCode.push({ codeSysId: '', codeSysName: 'cas', substanceCode: row.cas })
        } else {
          this.$message('根据unii未找到物质结构信息')
        }

      })
    },
    /*添加物质属性输入表单组件*/
    addSubstanceProp($event, name) {
      let select = this.drug.dkbSubstanceProps
      //属性名称-设置默认值
      if (isEmpty(name) && this.substanceProps && this.substanceProps.length > 0) {
        name = this.substanceProps[0].conceptId
      }
      // 还原下拉列表
      this.substancePropsValOptions[name] = this.substancePropsVal[name]
      let val
      if (this.substancePropsVal[name] && this.substancePropsVal[name].length > 0) {
        val = this.substancePropsVal[name][0].conceptId
      }
      select.push({
        name: name,
        value: val
      })

    },
    /*移除物质属性输入表单组件*/
    delSubstanceprop(item) {
      var index = this.drug.dkbSubstanceProps.indexOf(item)
      this.drug.dkbSubstanceProps.splice(index, 1)
    },
    /* 物质属性-重复性校验*/
    validateSubstanceProps(rule, value, callback, index) {
      let propsList = this.drug.dkbSubstanceProps
      let key = Object.keys(index)[0]
      let nowIndex = key.replaceAll('dkbSubstanceProps.', '').replaceAll('.name', '')
      let now = propsList[nowIndex]
      propsList.forEach(function(item, index) {
        if (index != nowIndex && item.name == now.name && item.value == now.value) {
          callback(new Error('属性已存在,请勿重复添加'))
        }
      })
      callback()
    },
    /*属性名称下拉列表选项变化*/
    propsNameSelectChange(value, index) {
      // 清空关联的属性值下拉列表选项
      this.drug.dkbSubstanceProps[index].value = ''
      let val
      if (this.substancePropsVal[value] && this.substancePropsVal[value].length > 0) {
        val = this.substancePropsVal[value][0].conceptId
      }
      // 关联的属性值下拉列表选项赋值为第一个选项
      this.drug.dkbSubstanceProps[index].value = val

    },
    /*物质属性值下拉搜索过滤*/
    substancePropsValFilter(query, name) {
      if (query !== '') {
        this.substancePropsValOptions[name] = this.substancePropsVal[name].filter(item => {
          return item.conceptVal.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.spell.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.wubi.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
        })

      } else {
        this.substancePropsValOptions[name] = this.substancePropsVal[name]
      }
    },

    /*单成分药物保存前，与物质信息做比较*/
    async  compareWithSubstance(){
      let substanceId=this.resultNameOptions[0].substanceId
      //获取物质信息
      /*let substance
      await  substanceInfo(substanceId).then(response => {
        this.substance=response.data
        substance=response.data
      })*/

      //合并数据
      this.mergeData()
      /*console.log("nameMergeResult",this.nameMergeResult)
      console.log("descMergeResult",this.descMergeResult)
      console.log("primaryKey",this.primaryKey)
*/


      //药物名称
      let dkbDrugAliasList=this.drug.dkbDrugAliasList
      //物质名称
      let dkbSubstanceName=this.drug.dkbSubstanceName

      //药物描述
      let dkbDrugDescList=this.drug.dkbDrugDescList
      //物质描述
      let dkbSubstanceDesc=this.drug.dkbSubstanceDesc

      if(dkbDrugAliasList.length>=dkbSubstanceName.length
        && dkbDrugAliasList.length==this.nameMergeResult.size
        && dkbDrugDescList.length>=dkbSubstanceDesc.length
        && dkbDrugDescList.length==this.descMergeResult.size
        && (this.primaryKey.substanceName==undefined || this.primaryKey.drugName==undefined ||this.primaryKey.substanceName==this.primaryKey.drugName )
        && (this.primaryKey.substanceDesc==undefined || this.primaryKey.drugDesc==undefined || this.primaryKey.substanceDesc==this.primaryKey.drugDesc)){
        // 物质和药物的  名字+描述+首选项完全一样 或 物质完全包含了药物的信息
        // 来源引用信息可能存在差异
        this.autoUpdate()
      }else{
        this.showCompareResult()
      }



    },



    /*显示比较结果弹窗*/
    showCompareResult(){
      this.compareResultDialogVisible=true

    },
    /*覆盖更新*/
    async overrideUpdate(){
      //console.log("===========覆盖更新overrideUpdate=============")
      //药物信息直接保存，物质的名称+名称来源引用+描述+描述的来源引用，替换为当前药物的


      //组织物质信息
      let substanceNameList=deepClone(this.drug.dkbDrugAliasList)
      let substanceDescList=deepClone(this.drug.dkbDrugDescList)
      substanceNameList.forEach(item=>{
        item.name=item.alias
        item.nameLangue=item.aliasLangue
        item.id=undefined
        if(item.dkbReferenceInfoList){
          item.dkbReferenceInfoList.forEach(info=>{
            info.id=undefined
            info.sourceTable=undefined
            info.sourceTableField=undefined
            info.sourceTableId=undefined
          })
        }
        item.dkbReferenceInfos=item.dkbReferenceInfoList
      })
      substanceDescList.forEach(item=>{
        item.substanceDesc=item.drugDesc
        item.id=undefined
        if(item.dkbReferenceInfoList){
          item.dkbReferenceInfoList.forEach(info=>{
            info.id=undefined
            info.sourceTable=undefined
            info.sourceTableField=undefined
            info.sourceTableId=undefined
          })
        }
        item.dkbReferenceInfos=item.dkbReferenceInfoList
      })


      //填充物质数据
      this.drug.dkbSubstance={}
      let dkbSubstance= this.drug.dkbSubstance
      if(this.drug.dkbDrugIngredientList && this.drug.dkbDrugIngredientList.length>0){
        dkbSubstance.id= this.drug.dkbDrugIngredientList[0].substanceId
      }
      dkbSubstance.relevanceId=this.drug.relevanceId
      dkbSubstance.relevanceName=this.drug.relevanceName
      dkbSubstance.dkbSubstanceCode=this.drug.dkbSubstanceCode
      dkbSubstance.dkbSubstanceStructure=this.drug.dkbSubstanceStructure
      dkbSubstance.dkbSubstanceProps=this.drug.dkbSubstanceProps
      dkbSubstance.dkbSubstanceName=substanceNameList
      dkbSubstance.dkbSubstanceDesc=substanceDescList

      saveOrUpdate(this.drug).then(response => {
        this.msgSuccess(response.msg)
        this.newInsertId=response.data
        this.compareResultDialogVisible=false
        this.returnPage()
      })

    },
    /*合并数据*/
    mergeData(){
      let mergeNameMap=new Map();
      let mergeDescMap=new Map();

      //处理物质名称
      this.drug.dkbSubstanceName.forEach(item=>{
        let key=item.name+'#'+item.nameLangue+'#'+item.aliasType
        mergeNameMap.set(key,item)
        let mergeReferenceInfoMap=new Map();
        if(item.dkbReferenceInfos){
          item.dkbReferenceInfos.forEach(infoItem=>{
            let infoKey=infoItem.resourceTypeId+'#'+infoItem.resourceId
            mergeReferenceInfoMap.set(infoKey,infoItem)
          })
        }
        item.substanceReferenceInfos=item.dkbReferenceInfos||[]
        item.drugReferenceInfos=[]
        item.mergeReferenceInfoMap=mergeReferenceInfoMap
        if(item.isPrimary=='1'){
          this.primaryKey.substanceName=key
        }
      })


      // 处理药物名称
      this.drug.dkbDrugAliasList.forEach(item=>{
        let key=item.alias+'#'+item.aliasLangue+'#'+item.aliasType
        item.name=item.alias
        item.nameLangue=item.aliasLangue
        let mergeReferenceInfoMap=new Map();
        if(mergeNameMap.get(key)){
          //已存在的名称，合并来源引用信息
          mergeReferenceInfoMap=mergeNameMap.get(key).mergeReferenceInfoMap
        }else{
          //新名称
          mergeNameMap.set(key,item)
          item.substanceReferenceInfos=[]
          item.mergeReferenceInfoMap=mergeReferenceInfoMap
        }

        if(item.dkbReferenceInfoList){
          item.dkbReferenceInfoList.forEach(infoItem=>{
            let infoKey=infoItem.resourceTypeId+'#'+infoItem.resourceId
            mergeReferenceInfoMap.set(infoKey,infoItem)
          })
        }

        mergeNameMap.get(key).drugReferenceInfos=item.dkbReferenceInfoList||[]
        if(item.isPrimary=='1'){
          this.primaryKey.drugName=key
        }

      })


      //处理物质描述
      this.drug.dkbSubstanceDesc.forEach(item=>{
        let key=item.substanceDesc
        item.desc=item.substanceDesc
        mergeDescMap.set(key,item)
        let mergeReferenceInfoMap=new Map();
        if(item.dkbReferenceInfos){
          item.dkbReferenceInfos.forEach(infoItem=>{
            let infoKey=infoItem.resourceTypeId+'#'+infoItem.resourceId
            mergeReferenceInfoMap.set(infoKey,infoItem)
          })
        }
        item.mergeReferenceInfoMap=mergeReferenceInfoMap
        item.substanceReferenceInfos=item.dkbReferenceInfos||[]
        item.drugReferenceInfos=[]
        if(item.isPrimary=='1'){
          this.primaryKey.substanceDesc=key
        }
      })

      // 处理药物描述
      this.drug.dkbDrugDescList.forEach(item=>{
        let key=item.drugDesc
        item.desc=item.drugDesc
        let mergeReferenceInfoMap=new Map();
        if(mergeDescMap.get(key)){
          //已存在的描述，合并来源引用信息
          mergeReferenceInfoMap=mergeDescMap.get(key).mergeReferenceInfoMap
        }else{
          //新描述
          mergeDescMap.set(key,item)
          item.substanceReferenceInfos=[]
          item.mergeReferenceInfoMap=mergeReferenceInfoMap
        }

        if(item.dkbReferenceInfoList){
          item.dkbReferenceInfoList.forEach(infoItem=>{
            let infoKey=infoItem.resourceTypeId+'#'+infoItem.resourceId
            mergeReferenceInfoMap.set(infoKey,infoItem)
          })
        }
        mergeDescMap.get(key).drugReferenceInfos=item.dkbReferenceInfoList||[]
        if(item.isPrimary=='1'){
          this.primaryKey.drugDesc=key
        }
      })

      //组装名称数据
      mergeNameMap.forEach((item)=>{
        let newItem={
          name:item.name,
          alias:item.name,
          nameLangue:item.nameLangue,
          aliasLangue:item.nameLangue,
          aliasType:item.aliasType,
          isPrimary:'0',
          dkbReferenceInfos:[],
          dkbReferenceInfoList:[]
        }
        item.mergeReferenceInfoMap.forEach(infoItem=>{
          newItem.dkbReferenceInfos.push({
            resourceTypeId:infoItem.resourceTypeId,
            resourceId:infoItem.resourceId
          })
        })
        newItem.dkbReferenceInfoList=newItem.dkbReferenceInfos
        //nameList.push(newItem)
        item.newItem=newItem
      })

      //组装描述数据
      mergeDescMap.forEach((item)=>{
        let newItem={
          drugDesc:item.desc,
          substanceDesc:item.desc,
          isPrimary:'0',
          dkbReferenceInfos:[],
          dkbReferenceInfoList:[]
        }
        item.mergeReferenceInfoMap.forEach(infoItem=>{
          newItem.dkbReferenceInfos.push({
            resourceTypeId:infoItem.resourceTypeId,
            resourceId:infoItem.resourceId
          })
        })
        newItem.dkbReferenceInfoList=newItem.dkbReferenceInfos
        //descList.push(newItem)
        item.newItem=newItem
      })

      this.nameMergeResult=mergeNameMap
      this.descMergeResult=mergeDescMap
    },

    /*合并更新*/
    async mergeUpdate(){
      //console.log("===========合并更新mergeUpdate=============",this.nameMergeResult,this.descMergeResult)
      let nameList=[]
      this.nameMergeResult.forEach((item,key)=>{
        nameList.push(item.newItem)
      })

      let descList=[]
      this.descMergeResult.forEach((item,key)=>{
        descList.push(item.newItem)
      })

      //数据合并后，都设置第一个描述为默认值
      if(nameList.length>0){
        nameList[0].isPrimary='1'
      }

      if(descList.length>0){
        descList[0].isPrimary='1'
      }

      this.drug.dkbDrugAliasList=nameList
      this.drug.dkbDrugDescList=descList
      //填充物质数据
      this.drug.dkbSubstance={}
      let dkbSubstance= this.drug.dkbSubstance
      if(this.drug.dkbDrugIngredientList && this.drug.dkbDrugIngredientList.length>0){
        dkbSubstance.id= this.drug.dkbDrugIngredientList[0].substanceId
      }
      dkbSubstance.relevanceId=this.drug.relevanceId
      dkbSubstance.relevanceName=this.drug.relevanceName
      dkbSubstance.dkbSubstanceCode=this.drug.dkbSubstanceCode
      dkbSubstance.dkbSubstanceStructure=this.drug.dkbSubstanceStructure
      dkbSubstance.dkbSubstanceProps=this.drug.dkbSubstanceProps
      dkbSubstance.dkbSubstanceName=this.drug.dkbDrugAliasList
      dkbSubstance.dkbSubstanceDesc=this.drug.dkbDrugDescList
      saveOrUpdate(this.drug).then(response => {
        this.msgSuccess(response.msg)
        this.newInsertId=response.data
        this.compareResultDialogVisible=false
        this.returnPage()
      })




    },
    /*忽略更新(物质名称和物质描述不做任何处理，药品数据直接保存)*/
    ignoreUpdate(){
      //console.log("===========忽略更新ignoreUpdate=============")
      //填充物质数据
      this.drug.dkbSubstance={}
      let dkbSubstance= this.drug.dkbSubstance
      if(this.drug.dkbDrugIngredientList && this.drug.dkbDrugIngredientList.length>0){
        dkbSubstance.id= this.drug.dkbDrugIngredientList[0].substanceId
      }
      dkbSubstance.relevanceId=this.drug.relevanceId
      dkbSubstance.relevanceName=this.drug.relevanceName
      dkbSubstance.dkbSubstanceCode=this.drug.dkbSubstanceCode
      dkbSubstance.dkbSubstanceStructure=this.drug.dkbSubstanceStructure
      dkbSubstance.dkbSubstanceProps=this.drug.dkbSubstanceProps
      saveOrUpdate(this.drug).then(response => {
        this.msgSuccess(response.msg)
        this.newInsertId=response.data
        this.compareResultDialogVisible=false
        this.returnPage()
      })
    },
    /*自动更新（物质和药物的  名字+描述+首选项完全一样 或 药物完全包含了物质的信息，此时来源引用信息可能不一致，自动合并名称、描述、来源引用后更新）*/
    async autoUpdate(){
      //console.log("===========自动更新autoUpdate=============",this.primaryKey,this.nameMergeResult,this.descMergeResult)
      //设置首选项
      if(this.primaryKey.drugName){
        let nameItem= this.nameMergeResult.get(this.primaryKey.drugName)
        nameItem.newItem.isPrimary='1'
      }

      if(this.primaryKey.drugDesc){
        let descItem= this.descMergeResult.get(this.primaryKey.drugDesc)
        descItem.newItem.isPrimary='1'
      }





      let nameList=[]
      this.nameMergeResult.forEach((item,key)=>{
        nameList.push(item.newItem)
      })

      let descList=[]
      this.descMergeResult.forEach((item,key)=>{
        descList.push(item.newItem)
      })

      this.drug.dkbDrugAliasList=nameList
      this.drug.dkbDrugDescList=descList
      //填充物质数据
      //填充物质数据
      this.drug.dkbSubstance={}
      let dkbSubstance= this.drug.dkbSubstance
      if(this.drug.dkbDrugIngredientList && this.drug.dkbDrugIngredientList.length>0){
        dkbSubstance.id= this.drug.dkbDrugIngredientList[0].substanceId
      }
      dkbSubstance.relevanceId=this.drug.relevanceId
      dkbSubstance.relevanceName=this.drug.relevanceName
      dkbSubstance.dkbSubstanceCode=this.drug.dkbSubstanceCode
      dkbSubstance.dkbSubstanceStructure=this.drug.dkbSubstanceStructure
      dkbSubstance.dkbSubstanceProps=this.drug.dkbSubstanceProps
      dkbSubstance.dkbSubstanceName=this.drug.dkbDrugAliasList
      dkbSubstance.dkbSubstanceDesc=this.drug.dkbDrugDescList


      saveOrUpdate(this.drug).then(response => {
        this.msgSuccess(response.msg)
        this.newInsertId=response.data
        this.compareResultDialogVisible=false
        this.returnPage()
      })

    },
    /*搜索关联物质*/
    async queryRelevanceSubstance(query) {
      if (!isEmpty(query)) {
        //this.loading = true
        let res = await selectSubstanceNameList({queryStr:query.trim()})
        if (res.code === 200) {
          //this.loading = false
          this.relevanceSubstanceList = res.data
        }
      } else {
        this.relevanceSubstanceList = []
      }
    },
    async editNext(){
      //console.log("药物editNext",this.pageData,this.queryParams)
      if(this.pageData==undefined){
        //刷新页面直接进入edit页面，点击下一页上一页跳转到列表页面
        this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
          this.$router.push('/dkm/drug')
          return
        })
      }
      //是否是编辑单物质药物
      let editOligomictDrug=this.$route.path.indexOf("editOligomictDrug")>0?true:false

      let nowIndex=-1
      let id=this.drug.id
      if(editOligomictDrug){
        //编辑的是单物质药物
        id=this.originalSubstanceId
      }

      this.pageData.some((item,index)=>{
        if(item.id==id){
          nowIndex=index
          return true
        }
      })
      if(nowIndex>-1 && nowIndex< this.pageData.length-1){
        id=this.pageData[nowIndex+1].id
      }else{
        //查询下一页数据
        this.queryParams.pageNum++
        //向上取整
        let maxPage=Math.ceil(this.queryParams.total/this.queryParams.pageSize)
        if (this.queryParams.pageNum>maxPage){
          this.msgInfo('已经是最后一条记录了')
          this.queryParams.pageNum--
          return;
        }
        let response
        if(editOligomictDrug){
          response=await substanceList(this.queryParams)
        }else{
          response=await list(this.queryParams)
        }

        this.queryParams.total=response.total
        setCache("drugQueryParams",this.queryParams)
        setCache("drugPageData",response.rows)
        setCache("substanceQueryParams",this.queryParams)
        setCache("substancePageData",response.rows)
        this.pageData= response.rows
        id=this.pageData[0].id
      }
      this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
        if(editOligomictDrug){
          //如果当前编辑的是单成分药物，此处获取的id，是物质id,需要获取物质列表的下一条记录，并判断是否含有只含该物质的单成分药物
          //查询获取只含有该物质的单成分药物信息
          oligomictDrugInfo(id).then(response => {
            if(response.data){
              //存在只含有该物质的药物,直接跳转到药物编辑界面
              this.$router.push('/drug/editOligomictDrug/' + response.data.id)
            }else{
              //跳转到物质编辑界面
              this.$router.push('/substance/edit/' + id)
            }
          })
        }else{
          this.$router.push('/drug/edit/' + id)
        }
      })
    },
    async editPrev(){
      //console.log("药物editPrev",this.pageData,this.queryParams)
      if(this.pageData==undefined){
        //刷新页面直接进入edit页面，点击下一页上一页跳转到列表页面
        this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
          this.$router.push('/dkm/drug')
          return
        })
      }
      //是否是编辑单物质药物
      let editOligomictDrug=this.$route.path.indexOf("editOligomictDrug")>0?true:false
      let nowIndex=-1
      let id=this.drug.id
      if(editOligomictDrug){
        //编辑的是单物质药物
        id=this.originalSubstanceId
      }
      this.pageData.some((item,index)=>{
        if(item.id==id){
          nowIndex=index
          return true
        }
      })
      //console.log(id,this.pageData,nowIndex,"-----",this.queryParams,this.queryParams.pageNum)
      if(nowIndex>0 ){
        id=this.pageData[nowIndex-1].id
      }else{
        //查询上一页数据
        this.queryParams.pageNum--
        if(this.queryParams.pageNum<1){
          this.msgInfo('已经是第一条记录了')
          this.queryParams.pageNum++
          return;
        }
        let response
        if(editOligomictDrug){
          response=await substanceList(this.queryParams)
        }else{
          response=await list(this.queryParams)
        }
        this.queryParams.total=response.total
        setCache("drugQueryParams",this.queryParams)
        setCache("drugPageData",response.rows)
        setCache("substanceQueryParams",this.queryParams)
        setCache("substancePageData",response.rows)
        this.pageData= response.rows
        id=this.pageData[response.rows.length-1].id
      }
      this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
        if(editOligomictDrug){
          //如果当前编辑的是单成分药物，此处获取的id，是物质id,需要获取物质列表的下一条记录，并判断是否含有只含该物质的单成分药物
          //查询获取只含有该物质的单成分药物信息
          oligomictDrugInfo(id).then(response => {
            if(response.data){
              //存在只含有该物质的药物,直接跳转到药物编辑界面
              this.$router.push('/drug/editOligomictDrug/' + response.data.id)
            }else{
              //跳转到物质编辑界面
              this.$router.push('/substance/edit/' + id)
            }
          })
        }else{
          this.$router.push('/drug/edit/' + id)
        }
      })

    },
    /*移除一个药物组*/
    handleRemoveDrugGroup(item,index) {
      this.drug.dkbDrugGroupMappingList.splice(index, 1);
    },
    /*选择一个药物组*/
    handleSelectDrugGroup() {
      //检查是否重复选择药物组
      let exit=false
      this.drug.dkbDrugGroupMappingList.some(item=>{
        if(item.drugGroupId==this.addDrugGroupForm.id){
          exit=true
          return true
        }
      })
      if(exit){
        this.msgInfo('请勿重复添加')
        return
      }

      let obj = {};
      obj = this.drugComponentTableData.find((item)=>{
        return item.id === this.addDrugGroupForm.id;
      });
      this.drug.dkbDrugGroupMappingList.push({drugGroupId:obj.id,drugGroupName:obj.name})
      this.drugComponentTableData=[]
      this.addDrugGroupForm.id=undefined
      this.addDrugGroupInputVisible = false;

    },
    /*显示药物组输入框*/
    showDrugGroupInput() {
      this.addDrugGroupInputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.focus();
      });
    },
    /*药物组输入框失去焦点事件*/
    addDrugGroupInputBlur(visible){
      if(!visible){
        this.addDrugGroupInputVisible = false;
        this.drugComponentTableData=[]
        this.addDrugGroupForm.id=undefined
      }
    },
    /*根据英文名称-自动填充unii信息*/
    autoFillUnii(){
      let nameItem
      nameItem = this.drug.dkbDrugAliasList.find((item) => {
        return item.aliasType == conceptConst.PUB_NAME_TYPE_EN && !isEmpty(item.alias)
      })
      if(nameItem){
        this.unii.form.searchValue=nameItem.name
        selectUniiOrNames(this.unii.form).then(response => {
          if(response.rows && response.rows.length>0){
            this.chooseUniiData(0, response.rows[0])
          }
        })
      }

    },
    //编辑UNII对应的cas信息
    editCas(substanceId,unii){
      this.$prompt('请输入cas编码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        if(isEmpty(value)){
          this.msgInfo("cas编码不能为空")
          return
        }else if(value.length>20){
          this.msgInfo("cas编码长度不能超过20")
          return
        }
        updateCas(substanceId,unii,value).then(res=>{
          this.drug.dkbSubstanceCode.some(item=>{
            if (item.codeSysName === 'cas') {
              item.substanceCode=value
              return true
            }
          })
          this.msgSuccess()
        })
      }).catch(() => {
      });
    }

  }
}
</script>


<style lang="scss" scoped>

/*分类标题*/
.title-bar {
  border-left: 5px solid #0073E9;
  padding-left: 10px;
  border-radius: 2px;
  margin-bottom: 20px;
}

/*删除按钮*/
.el-icon-remove {
  color: #F52C2C;
  font-size: 20px;
  padding: 0px 6px;
}

/*药物编码div*/
#substanceCodeDiv {
  width: 100%;
  /*height: 155px;*/
  background: #EBF0FA;
  border: 1px solid #BDD3FF;
  border-radius: 4px;
  padding: 10px 20px;
  float:left;
  position: relative;
}

#substanceCodeDiv p {
  height: 14px;
  line-height: 14px;
  padding:5px 0px;
}

#substanceCodeDiv label {
  display:block;
  float:left;
  width:80px;
  text-align:right;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #666666;
  margin-left: 20px;
}

#substanceCodeDiv span {
  width:700px;
  display:block;
  float:left;
  padding-left:5px;
  font-size: 14px;
  line-height:18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}

.bottom-bar {
  width: 100%;
  height: 30px;
  position: fixed;
  bottom: 60px;
  z-index: 200;
  font-size: 12px;
  line-height: 30px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: center;
  padding-right: 260px;
}

/*推荐属性*/
#recommendProps button {
  min-width: 100px;
  color: #2B60F8
}

.el-table .cell .el-form-item {
  margin-bottom: 0px;
}
.jgs_pic{
  width: 180px;
  height: 180px;
  background: #EBF0FA;
  border: 1px solid #BDD3FF;
  border-radius: 4px;
  margin-top:58px;
  position: absolute;
  right: 10px;
  top: -50px;
}
.spanWrap p{
  margin: 0!important;
}
.belongDrug{
  width: 148px;
  height: 34px;
  background: #E6ECFE;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  display: block;
  text-align: center;
  margin-bottom: 10px;
  line-height: 34px;
  margin-left: 111px;
  position: relative;
}
.delIcon{
  position: absolute;
  right: -29px;
  top: 9px;
  color:#F52C2C;
}
.el-form-item{
  margin-bottom: 18px;
}
.navBarWrap {
  position:fixed;
  top: 50px;
  z-index: 100;
}

.el-tag {
  margin-right: 10px;
}
.button-new-tag {
  /*margin-left: 10px;*/
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 150px;
  margin-right: 10px;
  vertical-align: bottom;
}

.cas-edit{
  color: #4B56F2;
  cursor: pointer;
}
.cas-edit:hover{
  color: #7b82f6;
}
</style>
